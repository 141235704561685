import { kitFetchExternal } from "@kulturit/header-state";
import { Topic } from "../../topics/Topic";

export const TOPIC_ITEM_TYPES_FETCHED = 'topic/topicItemTypesFetched';

export const TOPIC_ITEM_TYPES_RECEIVED = 'topic/topicItemTypesReceived';

export const TOPIC_FETCHED = 'topic/topicFetched';

export const TOPIC_RECEIVED = 'topic/topicReceived';

export const getTopicItemTypes = () => dispatch => {
    dispatch({
        type: TOPIC_ITEM_TYPES_FETCHED
    })

    kitFetchExternal('/minne-portal-api/topics/item_types/')
    //fetch('http://localhost:5000/api/topics/item_types/')
    //    .then(response => response.json())
        .then(json => {
            dispatch({
                type: TOPIC_ITEM_TYPES_RECEIVED,
                topicItemTypes: json.topic_item_types
            })
        })

}

export const getTopic = (topicId, callback=null, onErrorCallback=null) => dispatch => {
    dispatch({
        type: TOPIC_FETCHED, topicId: topicId
    })
    kitFetchExternal(`/minne-portal-api/topics/${topicId}`)
    //    .then(response => response.json())
    // .catch(err => {
    //     onErrorCallback && onErrorCallback(err)
    // })
        .then(json => {
            let topic = new Topic(json)
            if(callback) {
                callback(topic);
            }
            dispatch({
                type: TOPIC_RECEIVED,
                topicId: topicId,
                topic: topic
            })
        })
        .catch(err => {
            onErrorCallback && onErrorCallback(err)
        })
}