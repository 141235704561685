import React from 'react';
import {IconButton} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock"

export const AppHeaderLogin = ({visible, loginHandler}) => {

    if(!visible) {
        return null
    }

    return (
        <IconButton
            color="inherit"
            aria-label="login"
            edge="start"
            onClick={loginHandler}
        >
            <LockIcon />
        </IconButton>
    )
};