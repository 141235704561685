import React from "react";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Widget, WidgetHeader, WidgetBody, Layout, Button } from "@frontend-components/admin";


export const StudentListItem = ({ student, onSelect, onRemove, onEdit, showClass }) => {

    const history = useHistory();

    function handleRemove(e) {
        if (!!onRemove) {
            e.preventDefault();
            onRemove(student);
        }
    }

    function handleEdit(e) {
        if (!!onEdit) {
            e.preventDefault();
            onEdit(student);
        }
    }

    function handleSelect(e) {
        if(!e.target.classList.contains("admin-button__icon")) {
            if(!!onSelect) {
                onSelect(student);
            }
        }
    }

    const { t, } = useTranslation();

    function getMemoryIcon(memories) {
        let result
        switch (memories) {
            case 0:
                result = 'filter_none'
                break;
            case 1:
                result = 'filter_1'
                break;
            case 2:
                result = 'filter_2'
                break;
            case 3:
                result = 'filter_3'
                break;
            case 4:
                result = 'filter_4'
                break;
            case 5:
                result = 'filter_5'
                break;
            case 6:
                result = 'filter_6'
                break;
            case 7:
                result = 'filter_7'
                break;
            case 8:
                result = 'filter_8'
                break;
            case 9:
                result = 'filter_9'
                break;
            default:
                result = 'filter_9_plus'
        }
        return result;
    }

    return (
        <Layout >
            <Widget onClick={e => handleSelect(e)}>
                <WidgetHeader >
                    {showClass &&
                        <p onClick={e => history.push(`/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}`)}>
                            <span>{t('app.schools.breadcrumbs.schoolClass')}</span>
                            <span>{student.schoolClass.title}</span>
                            &nbsp;(<span>{student.schoolClass.school.title}</span>)
                        </p>
                    }
                    <h1 className="student-header"><span>{student.name}</span> <span>{student.familyName}</span>
                        <span className="no-print">
                            <span 
                                className={`memory-indicator-button ${student.nbrOfMemoriesReadyForApproval > 0 ? "memories-needs-approval":""}` }
                                title={`${t('app.schools.texts.nbrOfMemoriesReadyForApproval')} : ${student.nbrOfMemoriesReadyForApproval}`}  
                            >
                                <i className="material-icons">{getMemoryIcon(student.nbrOfMemoriesReadyForApproval)}</i>
                            </span>
                        </span>
                        <span className="no-print">
                            {!!onRemove &&
                                <Button
                                    style={{ float: "right" }}
                                    onClick={e => handleRemove(e)}
                                    icon="delete" >
                                </Button>
                            }
                            {!!onEdit &&
                                <Button
                                    style={{ float: "right" }}
                                    onClick={e => handleEdit(e)}
                                    icon="edit" >
                                </Button>
                            }
                        </span>
                        
                    </h1>
                </WidgetHeader>
                <WidgetBody>
                    <p className="only-print">{`${t('app.schools.texts.studentLoginInfo')}`}</p>
                    <p>{`${t('app.schools.texts.password')}: ${student.token}`}</p>
                    <p className="no-print">{`${t('app.schools.texts.nbrOfMemories')} : ${student.nbrOfMemories}`}</p>
                    <p className="no-print">{`${t('app.schools.texts.nbrOfMemoriesNotReadyForApproval')} : ${student.nbrOfMemoriesNotReadyForApproval}`}</p>
                </WidgetBody>
            </Widget>
        </Layout>
    );
}