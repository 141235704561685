export class ResponseItem {
    constructor(response, topicItem) {
        this.holdsAnswer=false;
        this.isMedia = topicItem.topic_item_type_id === 9;
        this.key = topicItem.key;
        this.display_sort_index=topicItem.display_sort_index;
        this.form_sort_index=topicItem.form_sort_index;
        this.typeId = topicItem.topic_item_type_id;
        this.displayValue=getDisplayValueFromResponse(response, topicItem);
        this.sortIndex= topicItem.display_sort_index;
        let labelAndDescription = getLabelAndDecriptionFromTopicItem(topicItem);
        this.label=labelAndDescription.label;
        this.description = labelAndDescription.description;
        if (topicItem.options && topicItem.options.length > 0) {
            let options = {};
            for (let option of topicItem.options) {
                options[option.id]=getLocalizedObject(option.labels);
            }
            this.options = options;
        } else {
            this.options={};
        } 
        if(this.isMedia) {
            //Media
            this.media=getMediaFromResponse(response, topicItem);
            if(this.media.length >0) {
                this.holdsAnswer=true;
            }
        } else {
            this.media = [];
        }
        this.stringRepr=getStringRepr(this.typeId, this.displayValue, this.options);
        if(!this.holdsAnswer && !!this.displayValue) {
            this.holdsAnswer=true;
        }
    }
}

function getStringRepr(typeId, displayValue, options) {
    switch (typeId) {
        case 1: // Intput
            return displayValue;
        case 2: // Textarea
            return displayValue;
        case 3: // Radio
            return getSelectedOptions(displayValue, options);
        case 4: // Checkbox
            return getSelectedOptions(displayValue, options);
        case 5: //  Select
            return getSelectedOptions(displayValue, options);
        case 6: //  Single date
            return displayValue;
        case 7: // Date range
            return getDateRange(displayValue);
        // case 8: // Form help text 

        // case 9: // Media 
            
        // case 10: // WYSIWYG 
        
        // case 11: // Title
  
        case 13: // Coordinate
            return "Coordinate is not implemented yet"
        case 14: // Coordinates 
            return "Coordinates are not implemented yet"
        // case 15: // YouTube
  
        // case 16: // SoundCloud
        default:
            return "";
    }
}

function getSelectedOptions(displayValue, options) {
    let optionIds=displayValue.toString().split(',');
    return optionIds.filter(i => !!i).map(i=> options[+i]['label']).join(", ");

}      

function getDateRange(displayValue) {

    let from_date="";
    let to_date="";
    let dateArray=displayValue.toString().split('|');
    if(dateArray.length > 0) {
        from_date=dateArray[0];
        if(dateArray.length > 1) {
            to_date=dateArray[1];
            return `${from_date} - ${to_date}`;
        } else {
            return from_date;
        }
    }
}  

function getMediaFromResponse(response, topicItem) {
    let result = [];
    let isTentativeMemory = response && response.isTentative;
    let responseValue;
    if (isTentativeMemory) {
        responseValue = (response[topicItem.key] || {});
    } else {
        responseValue = response.response_values[topicItem.key] || {};
    }
    if(!! responseValue["image"]) {
        for(const i in responseValue["image"]) {
            let image = responseValue["image"][i];
            image.thumbnail=image.dms_url;
            image.license=getLicense(image.license);
            result.push(image);
        } 
    } 
    if(!! responseValue["video"]) {
        for(const i in responseValue["video"]) {
            let video = responseValue["video"][i];
            video.thumbnail=video.dms_poster;
            video.dms_video_url = getDMSVideoURL(video);
            video.license=getLicense(video.license);
            result.push(video);
        } 
    } 
    if(!! responseValue["audio"]) {
        for(const i in responseValue["audio"]) {
            let audio = responseValue["audio"][i];
            audio.dms_audio_url=getDMSAudioURL(audio);
            audio.license=getLicense(audio.license);
            result.push(audio);
        } 
    } 
    if(!! responseValue["document"]) {
        for(const i in responseValue["document"]) {
            let document = responseValue["document"][i];
            document.dms_docuement_url=getDMSDocuemntURL(document);
            document.license=getLicense(document.license);
            result.push(document);
        } 
    } 
    result.sort((a,b) => a.order_by_number-b.order_by_number)
    return result;
}

const licenseUrls = {
    "CC0": 'https://creativecommons.org/publicdomain/zero/1.0/deed.sv',
    "CC BY": 'https://creativecommons.org/licenses/by/4.0/deed.sv',
    "CC BY-NC": 'https://creativecommons.org/licenses/by-nc/4.0/deed.sv'
}

function getLicense(license) {
    if(!license) {
        return {
            value: "CC0",
            url: licenseUrls["CC0"]
        }
    }
    if(typeof license === 'object') {
        return license;
    }
    return {
        value: license,
        url: licenseUrls[license]
    };
}

function getDMSVideoURL(media) {
    return `${media.dms_url}.${getVideoFileExt(media.mime_type)}?mmid=${media.dms_id}`
}

function getDMSAudioURL(media) {
    return `${media.dms_url}.${getAudioFileExt(media.mime_type)}?mmid=${media.dms_id}`
}

function getDMSDocuemntURL(media) {
    return `${media.dms_url}`//.${getAudioFileExt(media.mime_type)}?mmid=${media.dms_id}`
}

function getVideoFileExt(mimetype) {
    return "mp4";
    // let result = mimetype.split('/');
    // return result[result.length-1];
}

function getAudioFileExt(mimetype) {
    return "mp3"; // Is this really true?
}

function getDisplayValueFromResponse(response, topicItem) {
    let isTentativeMemory = response && response.isTentative;
    let result = '';
    if (isTentativeMemory) {
        result = (response[topicItem.key] || {}).display_value || '';
    } else {
        let response_value = response.response_values[topicItem.key];
        if (response_value && response_value.display_value) {
            result = getLocalizedOrAsIs(response_value.display_value);
        }
    }
    return result;
};

function getLabelAndDecriptionFromTopicItem(topicItem) {
    return getLocalizedOrAsIs(topicItem.labels, { label: '', description: null });
};

export function getLocalizedOrAsIs(o, defaultValue = '') {
    let result;
    if (o !== null && typeof o === 'object') {
        var locale = "sv_se";
        var defaultLocale = "sv_se";
        result = o[locale] || o[defaultLocale] || Object.values(o)[0] || defaultValue;
    } else {
        result = o || defaultValue;
    }

    return result;
};

function getLocalizedObject(o) {
    if (o !== null) {
        let locale = "sv_se";
        let defaultLocale = "sv_se";
        return o[locale] || o[defaultLocale] || {};
    } else {
        return {};
    }
};
/* Current topic Item types
"1";"Input";TRUE
"2";"Textarea";TRUE
"3";"Radio";TRUE
"4";"Checkbox";TRUE
"5";"Select";TRUE
"6";"Single date";TRUE
"7";"Date range";TRUE
"8";"Form help text";TRUE
"9";"Media";TRUE
"10";"WYSIWYG";TRUE
"11";"Title";TRUE
"12";"Subjects";
"13";"Coordinate";TRUE
"14";"Coordinates";TRUE
"15";"YouTube";TRUE
"16";"SoundCloud";TRUE
*/