import React from "react";
import { useTranslation } from 'react-i18next';
import { RESPONSE, PUBLISH, RESEARCH, RETRACT, RETURN } from '../domain-objects/response'
import "./School.scss";

import { Button as OldStyleButton } from "@frontend-components/admin";
import { ResponseItem } from "./ResponseItem"
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {PrimaryButton, DefaultButton, AffirmButton, RejectButton, AbortButton} from '../gui-components/Buttons'


export const Response = ({ response, student, onUnselect }) => {

    const { t, } = useTranslation();

    function getTeachers() {
        return (student.schoolClass.teachers)?student.schoolClass.teachers:[];
    }

    function getFirstTeacherKey() {
        let teachers = getTeachers();
        return (teachers.length > 0 )?teachers[0].key:null;
    }

     /* Response actions */ 

    function handlePublish() {
        if (response.kind === RESPONSE) {
            // i.e. not SAVED_RESPONSE

            if (isAdmin() && !isTeacher()) {
                // TODO: select a teacher
                let ekulturId = getFirstTeacherKey();
                response.publish(student, PUBLISH, ekulturId).then(() => {
                    onUnselect(true);
                }
                ).catch(err => console.log(err));
            } else {
                response.publish(student, PUBLISH).then(() => {
                    onUnselect(true);
                }
                ).catch(err => console.log(err));
            }
        } else {
            response.changePublishStatus(student, PUBLISH).then(() => {
                onUnselect(true);
            }
            ).catch(err => console.log(err));
        }
    }

    function handleSubmit() {
        if (response.kind === RESPONSE) {
            // i.e. not SAVED_RESPONSE

            if(isAdmin() && ! isTeacher()) {
                // TODO: select a teacher
                let ekulturId = getFirstTeacherKey();
                response.publish(student, RESEARCH, ekulturId).then(() => {
                    onUnselect(true);
                }
                ).catch(err => console.log(err));
            } else {
                response.publish(student, RESEARCH).then(() => {
                    onUnselect(true);
                }
                ).catch(err => console.log(err));
            }
        } else {
            response.changePublishStatus(student, RESEARCH).then(() => {
                onUnselect(true);
            }
            ).catch(err => console.log(err));
        }

    }

    function handleRetract() {
        response.changePublishStatus(student, RETRACT).then(() => {
            onUnselect(true);
        });
    }

    function handleReturn() {
        response.changePublishStatus(student, RETURN).then(() => {
            onUnselect(true);
        }
        ).catch(err => console.log(err));
    }

    /* Roles */ 

    function isTeacher() {
        return response.actorStatus.isTeacherInSchoolClass;
    }

    function isAdmin() {
        return response.actorStatus.isAdmin;
    }

    
   
 

  

   
   
    return (
        <div className="response-view">
            <h1>{response.topic.name}</h1>
            <h2>{response.title}</h2>
            <div className="response-view-close">
                <OldStyleButton
                    onClick={e => onUnselect(false)}
                    icon="clear" >
                </OldStyleButton> 
            </div>
            <div className="response-body">
                <div className="response-section">
                    {!!response.responseItems && response.responseItems.filter(ri => ri.isMedia && ri.holdsAnswer).map((responseItem) =>
                        <ResponseItem key={responseItem.key} responseItem={responseItem}></ResponseItem>
                    )}
                </div>
                <div className="response-section">
                    {!!response.responseItems && response.responseItems.filter(ri => !ri.isMedia && ri.holdsAnswer).map((responseItem) =>
                        <ResponseItem key={responseItem.key} responseItem={responseItem}></ResponseItem>
                    )}
                </div>
                <div className="response-section">
                    <div className="status">
                        { isAdmin() && 
                            <p className="allert-text"><span className="material-icons">warning</span> {t('app.schools.texts.youAreAdmin')} </p>
                        }
                        { response.isWorkedOnByStudent() && 
                            <p className="info-text">{t('app.schools.texts.memoryIsNotReadyForPublishing')} </p>
                        }
                        { response.isReadyForApproval() && 
                            <p className="info-text">{t('app.schools.texts.memoryIsReadyForPublishing')} </p>
                        }
                        { response.isPublished() && 
                            <p className="info-text">{t('app.schools.texts.memoryIsPublished')} </p>
                        }
                        { response.isSubmitted() && 
                            <p className="info-text">{t('app.schools.texts.memoryIsOnlySentIn')} </p>
                        }
                        { response.isSaved() && 
                            <p className="info-text">{t('app.schools.texts.memoryIsSaved')} </p> 
                        }
                    </div>
                    <div className="actions">
                            {(isTeacher() || isAdmin()) &&
                                <div className="button-group">
                                { (response.isReadyForApproval() || response.isSubmitted() || response.isSaved())  &&  
                                     <AffirmButton label={'app.schools.texts.publish'} startIcon={<AssignmentTurnedInIcon/>} onClick={handlePublish} />
                                }
                                { (response.isReadyForApproval() || response.isPublished() || response.isSaved())  &&  
                                    <PrimaryButton label={'app.schools.texts.sendIn'} startIcon={<AssignmentTurnedInIcon/>}  onClick={handleSubmit} />
                                }
                                { response.isApproved()  &&  
                                    <RejectButton label={'app.schools.texts.return'} startIcon={<AssignmentReturnedIcon />} onClick={handleReturn}/>
                                }
                                { response.isApproved()  &&  isAdmin() &&
                                    <DefaultButton label={'app.schools.texts.retract'} startIcon={<NotInterestedIcon />} onClick={handleRetract} />
                                }
                               </div>
                            }
                            <AbortButton onClick={e => onUnselect(false)} />            
                    </div>
                </div>
            </div>
        </div>
    );


}