import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { EditorContent, LayoutDocument, LayoutEditor } from "@frontend-components/admin";
import { getTopicItemTypes } from "../state/topic/topic-action";
import { SchoolClass } from "./SchoolClass.js";

import { setFinderActionMode } from "../state/layout/layout-action";
import { getSchool} from "../domain-objects/school";
import { getSchoolClass } from "../domain-objects/schoolClass";

import "./School.scss";

export const SchoolClassPage = () => {

    const dispatch = useDispatch();

    const { schoolId, schoolClassId } = useParams();

    const cachedSchools = useSelector(state => state.school.schools)

    const [schoolClass, setSchoolClass] = useState(null);

    // Breadcrumbs 
    const [parents, setParents] = useState(null);

    function loadSchoolClass(school, schoolClassId, ac) {
        getSchoolClass(school, schoolClassId, ac)
                    .then(schoolClass => {
                        schoolClass.reload = () => loadSchoolClass(school, schoolClassId, ac);
                        setSchoolClass(schoolClass);
                    })
                    .catch(err => console.log(err));

    }

    useEffect(() => {
        dispatch(setFinderActionMode());
        dispatch(getTopicItemTypes());

        const ac = new AbortController();


        getSchool(schoolId, dispatch, cachedSchools)
            .then(school => {
                loadSchoolClass(school, schoolClassId, ac);
            })
            .catch(err => {
                console.log(err);
                setSchoolClass(null);
            });

        return () => ac.abort();
    }, []);
    

    
    return (
        <LayoutEditor parents={parents}>
            <EditorContent>
                <LayoutDocument menu={null}>
                    { !!schoolClass &&
                        <SchoolClass schoolClass={schoolClass} setParents={setParents} />
                    } 
                </LayoutDocument>
            </EditorContent>
        </LayoutEditor >
    );

}