import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { LayoutField, ButtonPrimary, ButtonSecondary, ButtongroupInline, FieldLabel, FieldInput } from "@frontend-components/admin";
import Modal from 'react-modal';
import { validatePNum } from "../domain-objects/personnummer";


export const TeacherRegistrationModal = ({ school, onVerify, onAbort }) => {


    const [personnummer, setPersonnummer] = useState("");
    const [validPersonnummer, setValidPersonnummer] = useState(false);


    function verifyTeacher() {
        if (validPersonnummer) {
            onVerify(personnummer);
        } else {
            onAbort();
        }
    }

    function abortVerification() {
        onAbort();
    }

    function personnummerValidate(personnummer, event) {
        let valid = validatePNum(personnummer)
        setValidPersonnummer(valid);
        setPersonnummer(personnummer);
    }


    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const { t, } = useTranslation();

    return (
        <Modal
            isOpen={!!school}
            style={customStyles}
            contentLabel={t("app.schools.teacherVerification.verifyYouAreATeacher")}>
            <h1>{t("app.schools.teacherVerification.verifyYouAreATeacher")}</h1>
            <LayoutField>
                <FieldLabel 
                    label={`${t("app.schools.teacherVerification.providePersonnummer")}:`}
                >
                </FieldLabel>
                <FieldInput 
                    title={t("app.schools.teacherVerification.personnummer")} 
                    placeholder="ååååmmddnnnn" 
                    value={personnummer} 
                    onChange={personnummerValidate} 
                    required={true}
                >
                </FieldInput>
            </LayoutField>
            <LayoutField>
                <ButtongroupInline>
                    <ButtonPrimary
                        disabled={!validPersonnummer}
                        title={t("app.schools.teacherVerification.confirm")}
                        icon="done"
                        onClick={verifyTeacher}
                    >
                    </ButtonPrimary>
                    <ButtonSecondary
                        title={t("app.schools.teacherVerification.abort")}
                        icon="close"
                        onClick={abortVerification}
                    >
                    </ButtonSecondary>
                </ButtongroupInline>
            </LayoutField>
        </Modal>
    );
}