import { kitFetchExternal } from "@kulturit/header-state";
import { getTopics } from "../topics/Topic.js";
import { ResponseItem, getLocalizedOrAsIs } from "./responseItem";


export function getResponses(student, dispatch, cachedTopics, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let tentativeMemoriesFuture = kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/responses`, (ac) ? { signal: ac.signal } : {});
    tentativeMemoriesFuture.then(json => {
        let topicIds=json.memories.map(tentativeMemory => tentativeMemory.response.topic_id);
        getTopics(topicIds, dispatch, cachedTopics)
            .then((topics) => {
                let responses = extractResponsesFromTentativeMemories(json.memories, topics, json.actorStatus);
                futureResolve({responses :responses});
            }).catch(err => {
                futureResolve({failed : err});
            });
    }).catch(err => {
        futureResolve({failed : err});
    });
    return result;
}

function extractResponsesFromTentativeMemories(tentativeMemories, topics, actorStatus) {
    let result = tentativeMemories.map(tentativeMemory => {
        let topic=topics.get(tentativeMemory.response.topic_id);
        return new Response(tentativeMemory.response, topic, tentativeMemory.uuid, tentativeMemory.status, RESPONSE, actorStatus)
    });
    return result;
}

// export function getPublishedResponses(student, dispatch, cachedTopics, ac) {
//     let futureResolve, futureReject;
//     let result = new Promise((resolve, reject) => {
//         futureResolve = resolve;
//         futureReject = reject;
//     });
//     let responsesFuture = kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/published_memories`, (ac) ? { signal: ac.signal } : {});
//     responsesFuture.then(json => {
//         let topicIds=json.published_responses.map(response => response.topic_id);
//         getTopics(topicIds, dispatch, cachedTopics)
//             .then((topics) => {
//                 let responses = extractResponses(json.published_responses, topics, json.actorStatus);
//                 futureResolve({responses :responses});
//             }).catch(err => {
//                 futureResolve({failed : err});
//             });
//     }).catch(err => {
//         futureResolve({failed : err});
//     });
//     return result;
// }

// export function getSubmittedResponses(student, dispatch, cachedTopics, ac) {
//     let futureResolve, futureReject;
//     let result = new Promise((resolve, reject) => {
//         futureResolve = resolve;
//         futureReject = reject;
//     });
//     let responsesFuture = kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/submitted_memories`, (ac) ? { signal: ac.signal } : {});
//     responsesFuture.then(json => {
//         let topicIds=json.submitted_responses.map(response => response.topic_id);
//         getTopics(topicIds, dispatch, cachedTopics)
//             .then((topics) => {
//                 let responses = extractResponses(json.submitted_responses, topics, json.actorStatus);
//                 futureResolve({responses :responses});
//             }).catch(err => {
//                 futureResolve({failed : err});
//             });
//     }).catch(err => {
//         futureResolve({failed : err});
//     });
//     return result;
// }

export function getSavedResponses(student, dispatch, cachedTopics, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let responsesFuture = kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/saved_responses`, (ac) ? { signal: ac.signal } : {});
    responsesFuture.then(json => {
        let topicIds=json.saved_responses.map(response => response.topic_id);
        getTopics(topicIds, dispatch, cachedTopics)
            .then((topics) => {
                // let status = "saved"
                // if(json.saved_responses)
                let responses = extractSavedResponses(json.saved_responses, topics, json.actorStatus);
                futureResolve({responses :responses});
            }).catch(err => {
                futureResolve({failed : err});
            });
    }).catch(err => {
        futureResolve({failed : err});
    });
    return result;
}

function extractSavedResponses(responses, topics, actorStatus) {
    let result = responses.map(response => {
        let topic=topics.get(response.topic_id);
        return new Response(response, topic, response.uuid, {}, SAVED_RESPONSE, actorStatus)
    });
    return result;
}

// Response Kind 

export const SAVED_RESPONSE = "savedResponse";

export const RESPONSE = "reponse";


// Response State 

export const PUBLISHED = "published";

export const SUBMITTED = "submitted";

export const SAVED = "saved";

export const STUDENT_WORKING = "studentWorking";

export const READY_FOR_APPROVAL = "readyForApproval";

// Response Operations 

export const PUBLISH = "publish";

export const RESEARCH = "research";

export const RETRACT = "retract";

export const RETURN = "return";


export class Response {
    constructor(originalResponse, topic, key, status, kind, actorStatus) {
        //this.orig_response = originalResponse; // Not needed!!!
        this.actorStatus = actorStatus;
        this.key = key;
        this.topicId = originalResponse.topic_id;
        this.topic = Object.assign({}, topic);
        this.author=originalResponse.author;
        this.author_alias=originalResponse.author_alias;
        this.responseItems = [];
        this.imageUrl = getImageUrl(originalResponse, topic);
        this.status = status;
        this.kind = kind;
        if(kind === RESPONSE) {
            if(!!originalResponse.ready_for_approval) {
                this.state = READY_FOR_APPROVAL;
            } else {
                this.state = STUDENT_WORKING;
            }        
        } else {
            if(!!originalResponse.published) {
                this.state = PUBLISHED;
            } else if(!!originalResponse.submitted) {
                this.state = SUBMITTED;

            } else if(!!originalResponse.ready_for_approval) {
                this.state = READY_FOR_APPROVAL;
            } else if(!!originalResponse.open_for_student_edit) {
                this.state = STUDENT_WORKING;
            } else {
                this.state = SAVED;        
            }
        }
        
        this.title = getLocalizedOrAsIs(originalResponse.title, "");
        for (let topicItem of topic.items) {
            let responseItem = new ResponseItem(originalResponse, topicItem);
            if (responseItem.typeId === 11) {
                // Title -- don't add, but keep track of title
                this.title = responseItem.displayValue || this.title;
            } else if (responseItem.typeId === 8) {
                // Form help text -- don't add
            } else {
                this.responseItems.push(responseItem);
            }
        };
        this.slogan = getSlogan(originalResponse, this.responseItems);
    }

    getAuthorName() {
        if(this.author_alias) {
            return this.author_alias;
        } 
        return this.author;
    }

    isReadyForApproval() {
        return this.state === READY_FOR_APPROVAL;
    }

    isWorkedOnByStudent() {
        return this.state === STUDENT_WORKING;
    }

    isPublished() {
        return this.state === PUBLISHED;
    }

    isSubmitted() {
        return this.state === SUBMITTED;
    }

    isSaved() {
        return this.state === SAVED;
    }

    isApproved() {
        return this.isPublished() || this.isSubmitted();
    }


    publish(student, publishing_kind=PUBLISH, teacher_ekultur_id=null) {
        let payload = {};
        if(publishing_kind===PUBLISH) {
            payload = {
                publishing_kind: PUBLISH,
                ekultur_id: teacher_ekultur_id
            };
        } else if(publishing_kind===RESEARCH) {
            payload = {
                publishing_kind: RESEARCH,
                ekultur_id: teacher_ekultur_id
            };
        } else {
            payload = {
                publishing_kind: PUBLISH,
                ekultur_id: teacher_ekultur_id
            };
        }
        let url = `/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/memory/${this.key}/publish`
        let result = kitFetchExternal(url, {
            method: 'POST',
            body: JSON.stringify(payload)
        });
        return result;
    }

    changePublishStatus(student, publishing_kind=PUBLISH) {
        let payload = {};
        if(publishing_kind===PUBLISH) {
            payload = {
                publishing_kind: PUBLISH
            }
        } else if(publishing_kind===RESEARCH) {
            payload = {
                publishing_kind: RESEARCH
            }
        } 
        else if(publishing_kind===RETURN) {
            payload = {
                publishing_kind: RETURN
            }
        } else if(publishing_kind===RETRACT) {
            payload = {
                publishing_kind: RETRACT
            }
        } else {
            payload = {
                publishing_kind: PUBLISH
            }
        }
        let url = `/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}/memory/${this.key}/publish`
        let result = kitFetchExternal(url, {
            method: 'PATCH',
            body: JSON.stringify(payload)
        });
        return result;
    }
}

function getImageUrl(originalResponse, topic) {
    if (originalResponse.dms_id) {
        return `http://dms07.dimu.org/image/${originalResponse.dms_id}`;
    } else {
        return topic.image_url; // may be null!!
    }
}

function getSlogan(originalResponse, responseItems) {
    let slogan = originalResponse.slogan;
    if (!slogan || slogan === "") {
        slogan = "";
        let lowestSortIndexFound = 99999;
        for (let responseItem of responseItems) {
            if (isTextResponseItem(responseItem.typeId) && lowestSortIndexFound > responseItem.sortIndex) {
                if (responseItem.displayValue && responseItem.displayValue !== "") {
                    slogan = responseItem.displayValue;
                    lowestSortIndexFound = responseItem.sortIndex;
                }

            }
        }
    }
    return slogan;
}

function isTextResponseItem(topicItemTypeId) {
    // Note! WYSIWYG = 10 might be interestnig but also problematic
    return [1, 2].includes(topicItemTypeId);
}




