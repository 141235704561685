import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {push} from "connected-react-router";
import {setFinderMode} from "../state/layout/layout-action";


class Home extends Component {

    static propTypes = {
        redirect: PropTypes.func.isRequired,
        setFinderMode: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {
            setFinderMode,
        } = this.props;

        setFinderMode();
    }

    render() {
        return (<section className="info-text">
            <h1>Hej Lärare 👋</h1> 
            <h2>Här skapar du en insamling för din klass</h2>

            <p>För att skapa en insamling för din klass, leta upp din skola i vänsterspalten (hitta en skola), skapa din klass, och lägg in namnen på de elever som ska delta. Du får då en uppsättning lösenord som är unika för var och en av eleverna, och som de använder för att skicka in sin läxa till dig.</p>

            <h2>Det är du som styr</h2>

            <p>Det är också här i lärarportalen som du kommer att se läxorna som lämnats in, samt sänder dem vidare till Nordiska museet.</p>

            <p>Observera att du som lärare alltid har full kontroll över alla elevers bidrag. Inget hamnar på Minnen.se eller i Nordiska museets arkiv utan att din inblandning.</p>

            <h2>Hur kommer jag  igång?</h2>

            <p>Har du inte ett konto och är inloggad redan så är det första steget. Tryck bara på knappen i övre högra hörnet.</p>
        </section> );
    }
}

const mapStateToProps = state => ({

});

const actions = {
    redirect: path => push(path),
    setFinderMode
};

export default connect(mapStateToProps, actions)(Home)
