import React, { useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import { Widget, Layout} from "@frontend-components/admin";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import {SaveButton, AbortButton} from '../gui-components/Buttons'

export const TeacherAddListItem = ({possibleNewTeachers, onAdd, onAbort}) => {

    const { t, } = useTranslation();

    const [newTeacher, setNewTeacher] = useState(null);


    function updateNewTeacher(e) {
        let key = e.target.value;
        let n = possibleNewTeachers.find((t) => t.key === key);
        setNewTeacher(n);
    }

    useEffect(() => {
        if(!!possibleNewTeachers && possibleNewTeachers.length > 0) {
            setNewTeacher(possibleNewTeachers[0]);
        }   
    }, [possibleNewTeachers]);
   
    const useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
          },
        },
      }));


    const classes = useStyles();

    if (!!newTeacher) {
        return (
            <Layout>  
                <Widget>
                    <form  className={classes.root} noValidate autoComplete="off">
                        <FormControl>
                            <InputLabel id="possible-new-teacher-label">{t('app.schools.texts.teachers')}</InputLabel>
                            <Select
                                labelId="possible-new-teacher-label"
                                id="possible-new-teacher"
                                value={newTeacher.key}
                                onChange={updateNewTeacher}>
                                {possibleNewTeachers.map((t) => {
                                    return  <MenuItem value={t.key}>{t.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </form>
              
                    <div className="button-group">
                        <SaveButton onClick={()=> onAdd(newTeacher)} disabled={!newTeacher} />
                        <AbortButton onClick={onAbort} />
                    </div>
                </Widget>
            </Layout>
        )
    } else { return <p>Hej!</p>; };
}


