import { kitFetchExternal } from "@kulturit/header-state";
import { extractSchoolClass } from "./schoolClass";
import { getSchools } from "./school"

function extractStudent(st, schoolClass) {
    let scl = null;
    if (!!schoolClass) {
        scl = Object.assign({}, schoolClass);
    } else if (!!st.schoolClass) {
        scl = extractSchoolClass(st.schoolClass);
    }
    return (
        {
            key: st.id,
            name: st.name,
            familyName: st.family_name,
            schoolClass: scl,
            nbrOfMemories: st.nbrOfMemories,
            nbrOfMemoriesReadyForApproval: st.nbrOfMemoriesReadyForApproval,
            nbrOfMemoriesNotReadyForApproval: st.nbrOfMemoriesNotReadyForApproval,
            token: st.token,
            //unselect: () => setSelectedStudent(null),
            //remove: () => removeStudent(st.id)
        }
    );
}


export function extractStudents(json, schoolClass = null) {
    let result=json.map(entity => extractStudent(entity, schoolClass));
    orderStudents(result);
    return result
}

function orderStudents(students){
    let previousStudent = null;
    for (let student of students) {
        if (!!previousStudent) {
            student.previous = previousStudent;
            previousStudent.next = student;
        }
        previousStudent = student;
    }
}

export function getStudents(schoolClass, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let studentsFuture = kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/students`, (ac) ? { signal: ac.signal } : {})
    studentsFuture.then((json) => {
        let students = extractStudents(json.students,schoolClass);
        futureResolve(students);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}


export function getMyStudents(dispatch, cachedSchools, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let studentsFuture = kitFetchExternal(`/minne-api/schools/my_students`, (ac) ? { signal: ac.signal } : {})
    studentsFuture.then((json) => {
        let students = extractStudents(json.students, null);
        let schoolsIds = new Set();
        for (let student of students) {
            schoolsIds.add(student.schoolClass.school.key);
        }
        getSchools(schoolsIds, dispatch, cachedSchools).then((schools) => {
            let schoolsDict = {};
            for (let school of schools) {
                schoolsDict[school.key] = school;
            }
            for (let student of students) {
                let school = schoolsDict[student.schoolClass.school.key];
                if (!!school) {
                    student.schoolClass.school = Object.assign({}, school);
                }
            }
            futureResolve(students);
        });

    }).catch(err => {
        futureReject(err);
    });
    return result;
}

export function addStudent(student, schoolClass) {
    let result = kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/student`, {
        method: 'POST',
        body: JSON.stringify(student)
    });
    return result;
}

export function updateStudent(student) {
    let result = kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}`, {
        method: 'PATCH',
        body: JSON.stringify(student)
    });
    return result;
}

export function removeStudent(student) {
    let result = kitFetchExternal(`/minne-api/schools/school/${student.schoolClass.school.key}/school_class/${student.schoolClass.key}/student/${student.key}`, {
        method: 'DELETE'
    });
    return result;
}
