import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { getMyStudents, updateStudent } from "../domain-objects/student";
import { setFinderActionMode } from "../state/layout/layout-action";
import { getTopicItemTypes } from "../state/topic/topic-action";
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor, LayoutFlex } from "@frontend-components/admin";
import { Student } from "./Student";
import { StudentListItem } from "./StudentListItem";
import { StudentEditListItem } from "./StudentEditListItem";
import { studentBreadcrumb } from "../routing/AppRoutes";


import "./Schools.scss";

export const MyStudents = () => {

    const dispatch = useDispatch();
    const cachedSchools = useSelector(state => state.school.schools)

    const { t, } = useTranslation();

    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [editedStudent, setEditedStudent] = useState(null);

    // Breadcrumbs 
    const [parents, setParents] = useState(null);

    function loadStudents(ac) {
        getMyStudents(dispatch, cachedSchools, ac)
            .then(students => {
                setStudents(students);
                setSelectedStudent(null);
            })
            .catch(err => console.log(err));
    }

    function handleUpdateStudent(student) {
        updateStudent(student).then(() => {
            setEditedStudent(null);
            loadStudents();
        }).catch(err => console.log(err));
    }

    function handleStudentSelect(student) {
        setSelectedStudent(student);
        updateParents(student);
    }

    function handleStudentUnselect() {
        setSelectedStudent(null);
        updateParents(null);
    }

    function updateParents(student) {
        let result =[
            {
                title: t('app.schools.breadcrumbs.home'),
                url: '/'
            },
            {
                title: t('app.schools.breadcrumbs.myStudents'),
                onClick: handleStudentUnselect
            }
        ];
        if(!!student) {
            result.push(studentBreadcrumb(student, t, null));
        }
        return setParents(result);
    }


    useEffect(() => {
        dispatch(setFinderActionMode());
        dispatch(getTopicItemTypes());
        const ac = new AbortController();
        loadStudents(ac);
        updateParents(null);
        return () => ac.abort();
    }, []);

    return (
        <LayoutEditor parents={parents}>

            <EditorContent>

                <LayoutDocument>
                    {!!selectedStudent && (
                        <Student student={selectedStudent} onSelect={handleStudentSelect}  onUnselect={handleStudentUnselect}></Student>
                    )}
                    {!selectedStudent && (
                        <LayoutSection title={t('app.schools.breadcrumbs.myStudents')}>
                            <LayoutFlex>
                                {!!students && students.map((student) => {
                                    if (!editedStudent || editedStudent.key !== student.key) {
                                        return (
                                            <StudentListItem
                                                key={student.key}
                                                student={student}
                                                onSelect={handleStudentSelect}
                                                onRemove={null}
                                                onEdit={setEditedStudent}
                                                showClass={true}
                                            >
                                            </StudentListItem>
                                        );
                                    } else {
                                        return (
                                            <StudentEditListItem
                                                key={student.key}
                                                student={editedStudent}
                                                onSave={handleUpdateStudent}
                                                onAbort={(e) => setEditedStudent(false)}
                                            >
                                            </StudentEditListItem>
                                        );
                                    }
                                })}

                            </LayoutFlex>



                        </LayoutSection>
                    )}
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}

