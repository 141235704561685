import React, {useRef, useState} from 'react';
import Avatar from "@material-ui/core/Avatar";
import {IconButton} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '6px',
        marginBottom: '6px',
        maxWidth: "320px"
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    }
}));

export const AppHeaderUserNav = ({user, children, visible}) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const classes = useStyles();

    if(!visible) {
        return null
    }

    return (
        <>
            <IconButton
                color="inherit"
                aria-label="open usermenu"
                edge="start"
                onClick={() => setOpen(d => !d)}
                ref={anchorRef}
            >
                <Avatar
                    alt={user.name}
                    src={user.imageUrl}
                    className={classes.avatar}
                />
            </IconButton>
            <Popper open={open}
                    style={{zIndex: 2000}}
                    role={undefined}
                    transition
                    disablePortal={false}
                    anchorEl={anchorRef.current}
                    placement={"bottom"}
                    arrow={{
                        enabled: true,
                        element: anchorRef,
                    }}
            >
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <div className={classes.root} aria-expanded={open}>
                        <Paper elevation={24} square={true}>
                            {children}
                        </Paper>
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    )
};