import { kitFetchExternal } from "@kulturit/header-state";

function extractCounty(entity) {
    return {
        uuid: entity.uuid,
        title: entity.properties["entity.name"][0].value.sv,
        code: entity.properties["place.code"][0].value
    };
}

function extractCounties(json) {
    return json
        .filter(entity => entity.entityType === "Place")
        .map(entity => extractCounty(entity));
}

export function getAllCounties(ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let countiesFuture = kitFetchExternal(`/kulturnav-api/list/172a20f7-bb49-4947-a653-e53df6c24259/0/25`, (ac) ? { signal: ac.signal } : {});
    countiesFuture.then(json => {
        let counites = extractCounties(json);
        futureResolve(counites);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}

