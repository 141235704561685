import React, { useEffect, useState } from "react";
import { useTranslation} from 'react-i18next';
import { Student } from "./Student.js"
import { LayoutSection, LayoutFlex} from "@frontend-components/admin";
import { getStudents, addStudent, updateStudent, removeStudent } from "../domain-objects/student";
import { getAllTeachersAtSchool, addTeacherToClass, removeTeacherFromClass} from "../domain-objects/teacher";
import { StudentListItem } from "./StudentListItem";
import { StudentEditListItem } from "./StudentEditListItem";
import { TeacherListItem } from "./TeacherListItem";
import { TeacherAddListItem } from "./TeacherAddListItem";
import PrintIcon from '@material-ui/icons/Print';
import {PrimaryButton, AddButton} from '../gui-components/Buttons';
import { homeBreadcrumb, schoolsBreadcrumb, municipalityBreadcrumb, schoolBreadcrumb, schoolClassBreadcrumb, studentBreadcrumb } from "../routing/AppRoutes";
import "./School.scss";

export const SchoolClass = ({ schoolClass, setParents}) => {


    const { t, } = useTranslation();

    const [students, setStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [editedStudent, setEditedStudent] = useState(null);
    const [addingStudent, setAddingStudent] = useState(false);


    const [teachers, setTeachers] = useState(null);
    const [possibleNewTeachers, setPossibleNewTeachers] = useState(null);

    const [addingTeacher, setAddingTeacher] = useState(false);


    function loadStudents(ac) {
        getStudents(schoolClass, ac)
            .then(students => {
                setStudents(students);
                setSelectedStudent(null);
            })
            .catch(err => console.log(err));
    }


    function loadTeachers(ac) {
        getAllTeachersAtSchool(schoolClass.school, ac)
            .then(teachers => {
                let teachersInThisClass = teachers.filter((t) => t.schoolClasses.includes(schoolClass.key));
                let teachersNotInThisClass = teachers.filter((t) => !t.schoolClasses.includes(schoolClass.key));
                setTeachers(teachersInThisClass);
                setPossibleNewTeachers(teachersNotInThisClass);
            })
            .catch(err => console.log(err));
    }

    function studentMayBeDeleted(student) {
        return student.nbrOfMemories === 0;
    }

    function addNewStudent(student) {
        addStudent(student, schoolClass).then(() => {
            setAddingStudent(false);
            loadStudents();
        }).catch(err => console.log(err));

    }

    function handleStudentSelect(student) {
        setSelectedStudent(student);
        updateParents(student);
    }

    function handleStudentUnselect() {
        setSelectedStudent(null);
        updateParents(null);
    }

    function handleUpdateStudent(student) {
        updateStudent(student).then(() => {
            setEditedStudent(null);
            loadStudents();
        }).catch(err => console.log(err));
    }

    
    function onAddNewTeacher(newTeacher) {
        addTeacherToClass(newTeacher, schoolClass).then(() => {
            schoolClass.reload(); // Needed since student data structure holds scoll class (with teachers)
        });
        setAddingTeacher(false);
    }

    function onRemoveTeacherFromClass(teacher) {
        removeTeacherFromClass(teacher, schoolClass).then(() => {
            schoolClass.reload(); // Needed since student data structure holds scoll class (with teachers)
        }
        ).catch(err => console.log(err));
    }

    function handleRemoveStudent(student) {
        removeStudent(student).then(() => {
            loadStudents();
        }).catch(err => console.log(err));
    }

    function updateParents(student) {
        let result = [homeBreadcrumb(t), schoolsBreadcrumb(t,true)];
        if (!!schoolClass.school && !!schoolClass.school.municipality) {
            result.push(municipalityBreadcrumb(schoolClass.school.municipality, t, true));
            result.push(schoolBreadcrumb(schoolClass.school, t, true));
            if (!!schoolClass) {
                result.push(schoolClassBreadcrumb(schoolClass, t, handleStudentUnselect));
                if(!!student) {
                    result.push(studentBreadcrumb(student, t,null));
                }
            }
        } 
        return setParents(result);
    }

    useEffect(() => {
        const ac = new AbortController();
        loadStudents(ac);
        loadTeachers(ac);
        updateParents(null);
        return () => ac.abort();
    }, [schoolClass]);

    const schoolClassTitle = () => {
        if ("10" !== schoolClass.grade) {
            return `${t('app.schools.breadcrumbs.schoolClass')} ${schoolClass.title}`
        } else {
            return `GY - ${schoolClass.name}`
        }
    }

    if (!!selectedStudent) {
        return (<Student student={selectedStudent} onSelect={handleStudentSelect} onUnselect={handleStudentUnselect}></Student>);
    } else {
        return (
            <div>
                <LayoutSection title={t('app.schools.texts.studentsIn')+ " " + schoolClassTitle() + " " + t('app.schools.texts.at') + "  " + schoolClass.school.title }>
                    <div className="no-print button-group">
                        <PrimaryButton
                            label= {'app.schools.actions.printClassList'}
                            startIcon={<PrintIcon />}
                            onClick={() => window.print()} />
                         {!addingStudent && schoolClass.actorStatus.isTeacherInSchoolClass &&
                            <AddButton
                                label={'app.schools.actions.addStudent'}
                                onClick={(e) => setAddingStudent(true)}
                            />
                        }
                    </div>
                    
                    <LayoutFlex>
                        {!!addingStudent &&
                            <StudentEditListItem
                                student={null}
                                onSave={addNewStudent}
                                onAbort={(e) => setAddingStudent(false)}
                            >
                            </StudentEditListItem>
                        }
                        {!!students && students.sort((s1,s2)=>{return s1.name >= s2.name} ).map((student, index) => {
                            if (!editedStudent || editedStudent.key !== student.key) {
                                return (
                                    <div key={student.key} className={`student ${(index+1)%18===0?"end-block-of-students":""}`}>
                                        <StudentListItem
                                            key={student.key}
                                            student={student}
                                            onSelect={handleStudentSelect}
                                            onRemove={studentMayBeDeleted(student) ? handleRemoveStudent : null}
                                            onEdit={setEditedStudent}
                                            showClass={false}
                                        >
                                        </StudentListItem>
                                    </div>
                                );
                            } else {
                                return (
                                    <StudentEditListItem
                                        key={student.key}
                                        student={editedStudent}
                                        onSave={handleUpdateStudent}
                                        onAbort={(e) => setEditedStudent(false)}
                                    >
                                    </StudentEditListItem>
                                );
                            }
                        })}
                    </LayoutFlex>
                </LayoutSection>
                <div className="no-print">
                    <LayoutSection title={t('app.schools.texts.teachersIn')+ " " + schoolClassTitle()}>
                        <div className="button-group">
                            {!addingTeacher && !!possibleNewTeachers && possibleNewTeachers.length > 0  &&
                                <AddButton
                                    label={'app.schools.actions.addTeacher'}
                                    onClick={(e) => setAddingTeacher(true)}
                                />
                            }
                        </div>
                        <LayoutFlex>
                            {!!addingTeacher &&
                                <TeacherAddListItem
                                    possibleNewTeachers={possibleNewTeachers}
                                    onAdd={onAddNewTeacher}
                                    onAbort={(e) => setAddingTeacher(false)}
                                >
                                </TeacherAddListItem>
                            }
                        
                            {!!teachers && teachers.map((teacher) =>
                                <TeacherListItem
                                    key={teacher.key}
                                    teacher={teacher}
                                    onRemove={teacher.isCurrentActor ? null : onRemoveTeacherFromClass} >
                                </TeacherListItem>
                            )}
                           
                        </LayoutFlex>
                    </LayoutSection>
                </div>
            </div >
        );
    }
}