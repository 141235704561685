import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor, Widget, LayoutFlex, ButtonPrimary, ButtonSecondary, ButtongroupInline, LayoutField, FieldLabel, FieldInput, FieldSelect } from "@frontend-components/admin";
import { getTopicItemTypes } from "../state/topic/topic-action";
import { SchoolClass } from "./SchoolClass.js";
import { SchoolClassListItem } from "./SchoolClassListItem";
import { SchoolClassEditListItem } from "./SchoolClassEditListItem";
import { TeacherListItem } from "./TeacherListItem"
import { setFinderActionMode } from "../state/layout/layout-action";
import { getSchool } from "../domain-objects/school";
import { homeBreadcrumb, schoolsBreadcrumb, municipalityBreadcrumb, schoolBreadcrumb } from "../routing/AppRoutes";
import { getAllTeachersAtSchool } from "../domain-objects/teacher";
import { getClassesAtSchool, addClassToSchool, mayBeDeleted, removeClassFromSchool } from "../domain-objects/schoolClass";
import { getActorStatusForSchool, noStatus } from "../domain-objects/actor";

import "./School.scss";

export const School = () => {

    const dispatch = useDispatch();

    const { t, } = useTranslation();

    const history = useHistory();

    const { schoolId } = useParams();

    const cachedSchools = useSelector(state => state.school.schools)

    const [school, setSchool] = useState(null);
    const [actorStatus, setActorStatus] = useState(noStatus);

    const [schoolClasses, setSchoolClases] = useState(null);
    const [selectedScoolClass, setSelectedSchoolClass] = useState(null);
    //const [editedSchoolClass, setEditedSchoolClass] = useState(null);
    const [addingSchoolClass, setAddingSchoolClass] = useState(false);

    const [teachers, setTeachers] = useState(null);

    function loadClasses(school, ac) {
        getClassesAtSchool(school, ac)
            .then(result => {
                setSchoolClases(result.schoolClasses);
                setSelectedSchoolClass(null);
                setActorStatus(result.actorStatus);
            })
            .catch(err => console.log(err));
    }

    function addNewClass(newClass) {
        if (newClass.valid) {
            addClassToSchool(newClass, school)
                .then(() => loadClasses(school))
                .catch(err => console.log(err));
            setAddingSchoolClass(false);
        }
    }

    function handleSchoolClassSelect(schoolClass) {
        history.push(`/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}`);
        //setSelectedSchoolClass(schoolClass); //TODO: History push
    }

    function handleSchoolClassDelete(schoolClass) {
        removeClassFromSchool(schoolClass, school)
            .then(() => loadClasses(school))
            .catch(err => console.log(err));
    }

    useEffect(() => {
        dispatch(setFinderActionMode());
        dispatch(getTopicItemTypes());

        const ac = new AbortController();
        if (!!schoolId) {
            getActorStatusForSchool(schoolId, ac).then(actorStatus => {
                setActorStatus(actorStatus);
                if (actorStatus.isTeacherAtSchool || actorStatus.isAdmin) {
                    getSchool(schoolId, dispatch, cachedSchools)
                        .then(school => {
                            setSchool(school);
                            loadClasses(school, ac);
                            getAllTeachersAtSchool(school, ac)
                                .then(teachers => setTeachers(teachers))
                                .catch(err => console.log(err));
                        })
                        .catch(err => {
                            console.log(err);
                            setSchool(null);
                        });
                }
            });

        }
        return () => ac.abort();
    }, []);


    // Breadcrumbs 


    function parents() {
        let result = [homeBreadcrumb(t), schoolsBreadcrumb(t, true)];
        if (!!school && !!school.municipality) {
            result.push(municipalityBreadcrumb(school.municipality, t, true));
            result.push(schoolBreadcrumb(school, t, false));
        }
        return result;
    }

    return (
        <LayoutEditor parents={parents()}>
            <EditorContent>
                <LayoutDocument menu={null}>
                    {!!selectedScoolClass &&
                        <SchoolClass schoolClass={selectedScoolClass}></SchoolClass>
                    } {!selectedScoolClass && !!school &&
                        <div>
                            <LayoutSection title={`${t('app.schools.texts.selectSchoolClass')} (${t('app.schools.texts.toHandleStudents')}):`}>
                                <LayoutFlex>
                                    {!!schoolClasses && schoolClasses.map((schoolClass) =>
                                        <SchoolClassListItem
                                            key={schoolClass.key}
                                            schoolClass={schoolClass}
                                            onSelect={handleSchoolClassSelect}
                                            onDelete={(mayBeDeleted(schoolClass)) ? handleSchoolClassDelete : null}>
                                        </SchoolClassListItem>
                                    )}

                                    {!!addingSchoolClass &&
                                        <SchoolClassEditListItem
                                            schoolClass={null}
                                            schoolClasses={schoolClasses}
                                            onSave={addNewClass}
                                            onAbort={(e) => setAddingSchoolClass(false)}
                                        >
                                        </SchoolClassEditListItem>
                                    }

                                </LayoutFlex>
                                {!addingSchoolClass && actorStatus.isTeacherAtSchool &&
                                    <div className="add-widget no-print">
                                        <ButtonPrimary
                                            title={t('app.schools.texts.addSchoolClass')}
                                            icon="add"
                                            onClick={(e) => setAddingSchoolClass(true)}
                                        ></ButtonPrimary>
                                    </div>
                                }
                            </LayoutSection>
                            <LayoutSection title={`${t('app.schools.texts.teachers')}:`}>
                                <LayoutFlex>
                                    {!!teachers && teachers.map((teacher) =>
                                        <TeacherListItem
                                            key={teacher.key}
                                            teacher={teacher}>
                                        </TeacherListItem>
                                    )}
                                </LayoutFlex>
                            </LayoutSection>
                        </div>
                    }
                </LayoutDocument>
            </EditorContent>
        </LayoutEditor >
    );

}