import {LAYOUTMODE_CHANGED} from "./layout-action";

const initialState = {
    layout: 'dashboard',
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case LAYOUTMODE_CHANGED:
            return {
                ...state,
                layout: action.layout
            };
        default:
            return {
                ...state
            }
    }
};

export default layoutReducer