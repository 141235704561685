import React from "react";
//import { useTranslation } from 'react-i18next';
import { Widget, WidgetHeader, WidgetBody, Layout, Button } from "@frontend-components/admin";


export const TeacherListItem = ({ teacher, onSelect, onRemove }) => {


    function handleRemove(e) {
        if (!!onRemove) {
            e.preventDefault();
            onRemove(teacher);
        }
    }

    function handleSelect(e) {
        if (!(e.target.classList.contains("admin-button__icon") || e.target.classList.contains("mailto-link"))) {
            if(!!onSelect) {
                onSelect(teacher);
            } 
        }
    }

    //const { t, } = useTranslation();
    return (
        <Layout key={teacher.key}>
            <Widget onClick={e => handleSelect(e)} style={{ backgroundColor: teacher.isCurrentActor ? "white" : "lightgrey" }}>
                <WidgetHeader  style={{ height: "30px" }}>
                    <h1>{teacher.name}
                    {!!onRemove &&
                        <Button
                            style={{ float: "right" }}
                            onClick={e => handleRemove(e)}
                            icon="delete" >
                        </Button>
                    }
                    </h1>
                </WidgetHeader>
                <WidgetBody>
                    <p><a className="mailto-link" href={`mailto:${teacher.email}`}>{teacher.email}</a></p>
                </WidgetBody>
            </Widget>
        </Layout>
    );
}