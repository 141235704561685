import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { setFinderActionMode } from "../state/layout/layout-action";

import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor } from "@frontend-components/admin";
import { kitFetchExternal } from "@kulturit/header-state";

import "./Schools.scss";

export const Statistics = () => {

    const dispatch = useDispatch();

    const { t, } = useTranslation();



    const [statistics, setStatisitcs] = useState(null);


    function loadStatisitcs(ac) {
        let url = `/minne-api/schools/statistics`
        kitFetchExternal(url, {
            method: 'GET'
        }).then(statistics => {
            setStatisitcs(statistics);

        }).catch(err => {
            //TODO: Handle error better!
            console.log(`Problem retriving statistics`);
        });
    }


    useEffect(() => {
        dispatch(setFinderActionMode());
        const ac = new AbortController();  
        loadStatisitcs(ac);


        return () => ac.abort();
    }, []);

    return (
        <LayoutEditor

            parents={[
                {
                    title: t('app.schools.breadcrumbs.home'),
                    url: '/'
                },
                {
                    title: t('app.schools.breadcrumbs.statistics')
                }
            ]}>

            <EditorContent>

                <LayoutDocument>
                    <LayoutSection title={t('app.schools.statisitics.header')}>
                        { !!statistics &&
                        <table className="statistics">
                            <tbody>
                                { Object.keys(statistics).map((entryName) => 
                                    <tr>
                                        <td className="label">{t(`app.schools.statisitics.${entryName}`)}:</td>
                                        <td className="value">{statistics[entryName]}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        }

                    </LayoutSection>
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}