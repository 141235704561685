import React from "react";
import { useTranslation } from 'react-i18next';
import { Widget, WidgetHeader, WidgetBody, Layout, Button } from "@frontend-components/admin";


export const SchoolListItem = ({ school, isMySchool, onSelect, onRemove, onEdit, showMunicipality }) => {

    function handleRemove(e) {
        if (!!onRemove) {
            e.preventDefault();
            onRemove(school);
        }
    }

    function handleEdit(e) {
        if (!!onEdit) {
            e.preventDefault();
            onEdit(school);
        }
    }

    function handleSelect(e) {
        if (!e.target.classList.contains("admin-button__icon")) {
            !!onSelect && onSelect(school);
        }
    }

    const { t, } = useTranslation();
    return <Layout key={school.key}>
        <div title={isMySchool ? t('app.schools.texts.clickToShowClasses') : t('app.schools.texts.clickToRegister')}>
            <Widget
                onClick={e => handleSelect(e)}
                style={{ backgroundColor: isMySchool ? "#008fad" : "#fff", color: isMySchool ? "#fff" : "#000" }}
            >
                <WidgetHeader>
                    {showMunicipality && 
                        /* TODO: get municipality from school */
                        <p>{school.municipalityId}</p>
                    }
                    <h1>{school.title}
                        {!!onRemove &&
                            <Button
                                style={{ float: "right" }}
                                onClick={e => handleRemove(e)}
                                icon="delete" >
                            </Button>
                        }
                        {!!onEdit &&
                            <Button
                                style={{ float: "right" }}
                                onClick={e => handleEdit(e)}
                                icon="edit" >
                            </Button>
                        }
                    </h1>
                </WidgetHeader>
                <WidgetBody>
                    <p>{school.address.street}<br />
                        {school.address.postalCode} {school.address.postalPlace}</p>
                </WidgetBody>
            </Widget>
        </div>
    </Layout>;

}