import {TOPIC_RECEIVED, TOPIC_ITEM_TYPES_RECEIVED} from "./topic-action";

const initialState = {
    topicItemTypes: [],
    topics: []
};

const topicReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOPIC_ITEM_TYPES_RECEIVED:
            return {
                ...state,
                topicItemTypes: action.topicItemTypes
            }
        case TOPIC_RECEIVED: {
                
                return {
                    ...state,
                    topics: {
                        ...state.topics,
                        [action.topicId]: action.topic
                    }
                }
            }
        default:
            return {
                ...state
            }
    }
};

export default topicReducer
