import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: 'column',
    },
    button: {
        alignSelf: "center",
        maxWidth: "260px"
    },
}));

export const AppHeaderLogout = ({logoutHandler, buttonText='Sign out'}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                <Button
                    variant={"outlined"}
                    onClick={logoutHandler}
                    className={classes.button}
                >
                    {buttonText}
                </Button>
            </div>
            <Divider />
        </div>
    )
}