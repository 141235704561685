import React, { Component } from 'react';


class Page404 extends Component {
    render() {
        return (
            <div>
                404. Nothing too see here, move along.
            </div>
        );
    }
}

export default Page404;
