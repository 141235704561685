import { kitFetchExternal } from "@kulturit/header-state";
import { extractSchool } from "../../domain-objects/school";
import { getMunicipality } from "../../domain-objects/municipality";

export const SCHOOL_FETCHED = 'school/schoolFetched';

export const SCHOOL_RECEIVED = 'school/schoolReceived';

export const getSchool = (schoolId, callback=null, onErrorCallback=null) => dispatch => {
    dispatch({
        type: SCHOOL_FETCHED, schoolId: schoolId
    })
    kitFetchExternal(`/kulturnav-api/${schoolId}`)
        .then(json => {
            let school = extractSchool(json);
            getMunicipality(school.municipalityId).then(municipality => {
                school.municipality = municipality;
                if(callback) {
                    callback(school);
                }
                dispatch({
                    type: SCHOOL_RECEIVED,
                    topicId: schoolId,
                    topic: school
                });
            }).catch(err => {
                onErrorCallback && onErrorCallback(err)
            });
            
        })
        .catch(err => {
            onErrorCallback && onErrorCallback(err)
        });
}