import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Widget, ButtonPrimary, ButtonSecondary, ButtongroupInline, Layout, LayoutField, FieldLabel, FieldInput, FieldSelect } from "@frontend-components/admin";
import { grades, isValidNewClass} from "../domain-objects/schoolClass";

export const SchoolClassEditListItem = ({ schoolClass, schoolClasses, onSave, onAbort }) => {

    const [editedClass, setEditedClass] = useState(null);

    function updateClassName(newName) {
        let updatedClass = { ...editedClass, name: newName };
        updatedClass = { ...updatedClass, valid: isValidNewClass(updatedClass, schoolClasses) };
        setEditedClass(updatedClass);

    }

    function updateClassGrade(newGrade) {
        let updatedClass = { ...editedClass, grade: newGrade };
        updatedClass = { ...updatedClass, valid: isValidNewClass(updatedClass, schoolClasses) };
        setEditedClass(updatedClass);
    }

    function saveEditedClass() {
        if (!!onSave && editedClass.valid) {
            onSave(editedClass);
        }
    }

    function abortEdit() {
        setEditedClass(null);
        onAbort(schoolClass);
    }

    const { t, } = useTranslation();

    useEffect(() => {
        if (!!schoolClass) {
            setEditedClass(
                { ...schoolClass, valid: isValidNewClass(schoolClass, schoolClasses)  });
        } else {
            let newClass = { name: "", grade: "4", multiGrade: false, valid: false };
            newClass = { ...newClass, valid: isValidNewClass(newClass, schoolClasses) };
            setEditedClass(newClass);
        }
    }, [schoolClass]);

    if (!!editedClass) {
        return (
            <Layout>
                <Widget>
                    <LayoutField>
                        <FieldLabel label={t('app.schools.texts.grade')}></FieldLabel>
                        <FieldSelect
                            title={t('app.schools.texts.grade')}
                            options={grades}
                            multiple={editedClass.multiGrade}
                            value={editedClass.grade}
                            onChange={updateClassGrade}
                            required={true}></FieldSelect>
                        <FieldLabel label={t('app.schools.texts.className')}></FieldLabel>
                        <FieldInput
                            title={t('app.schools.texts.className')}
                            value={editedClass.name}
                            onChange={updateClassName}
                            required={true}></FieldInput>
                        <ButtongroupInline>
                            <ButtonPrimary 
                                disabled={!onSave || !editedClass.valid} 
                                title={t('app.schools.texts.save')} 
                                icon="done" 
                                onClick={saveEditedClass}
                            >
                            </ButtonPrimary>
                            <ButtonSecondary 
                                title={t('app.schools.texts.abort')} 
                                icon="close" 
                                onClick={abortEdit}
                            >
                            </ButtonSecondary>
                        </ButtongroupInline>

                    </LayoutField>
                </Widget>
            </Layout>
        )
    } else { return null; };
}