import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { getAllTeachers } from "../domain-objects/teacher";
import { setFinderActionMode } from "../state/layout/layout-action";

import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor, LayoutFlex} from "@frontend-components/admin";
import { TeacherListItem } from "./TeacherListItem";
import { getActorStatus, noStatus } from "../domain-objects/actor";
import uuid from 'uuid/v4';
import EmailIcon from '@material-ui/icons/Email';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from "@material-ui/core/Button";
// import {kitGetBlob} from '@kulturit/fetch';

import "./School.scss";

export const Teachers = () => {

    const dispatch = useDispatch();

    const { t, } = useTranslation();
    const history = useHistory();

    const [actorStatus, setActorStatus] = useState(noStatus);
    const [teachers, setTeachers] = useState([]);
    const [teacherEmails, setTeacherEmails] = useState([]);

    function loadTeachers(ac) {
        getAllTeachers(ac)
            .then(teachers => {
                setTeachers(teachers);
                setTeacherEmails(teachers.map(t => t.email).join(", "));
            })
            .catch(err => {
                //TODO: Handle error better!
                console.log(`Problem retriving teachers`);
            });
    }

    const sendMailToAllTeachers = () => {
        document.location.href = `mailto:${teacherEmails}`;
    };

    const downloadTeacherEmailList = () => {      
        let configuration = {
            headers:  new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('kit.access_token'),
                'Correlation-Id': uuid()
            })
        };
        let url = `/minne-api/schools/teachers/emails`;
        //url = '' + process.env.REACT_APP_API_GATEWAY +url;
        fetch(url, configuration)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'teachers_emails.xls';
                    a.click();
                });
        });
    };

    // const downloadTeacherEmailList = () => {      
       
    //     let url = `/minne-api/schools/teachers/emails`;
    //     kitGetBlob(url)
    //         .then(response => {
    //             response.blob().then(blob => {
    //                 let url = window.URL.createObjectURL(blob);
    //                 let a = document.createElement('a');
    //                 a.href = url;
    //                 a.download = 'teachers_emails.xls';
    //                 a.click();
    //             });
    //     });
    // };


    function handleTeacherSelect(teacher) {
        history.push(`/schools/teachers`);
        //history.push(`/schools/teachers/${teacher.key}`);
    }

    useEffect(() => {
        dispatch(setFinderActionMode());
        const ac = new AbortController();
        getActorStatus(ac).then(actorStatus => {
            setActorStatus(actorStatus);
            if(actorStatus.isAdmin) {
                loadTeachers(ac);
            }
        });
        
        return () => ac.abort();
    }, []);

    return (
        <LayoutEditor

            parents={[
                {
                    title: t('app.schools.breadcrumbs.home'),
                    url: '/'
                },
                {
                    title: t('app.schools.breadcrumbs.teachers')
                }
            ]}>

            <EditorContent>

                <LayoutDocument>
                    <LayoutSection title={t('app.schools.texts.teachers')}>
                       { !!teacherEmails && actorStatus.isAdmin && 
                            <div className="button-group">
                                <Button
                                    className="send-to-all-button"
                                    variant="contained"
                                    color={"primary"}
                                    startIcon={<EmailIcon />}
                                    onClick={sendMailToAllTeachers}
                                >{t('app.schools.texts.sendMailToAll')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color={"primary"}
                                    startIcon={<GetAppIcon />}
                                    onClick={downloadTeacherEmailList}
                                >{t('app.schools.texts.downloadEmailList')}</Button>
                               
                            </div>
                       }

                        <LayoutFlex>
                            {!!teachers && actorStatus.isAdmin && teachers.map((teacher) => {
                                return (
                                    <TeacherListItem
                                        key={teacher.key}
                                        teacher={teacher}
                                        onSelect={handleTeacherSelect}
                                    >                                       
                                    </TeacherListItem>
                                    
                                );

                            })}
                        </LayoutFlex>
                    </LayoutSection>
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}