import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { kitFetchExternal } from "@kulturit/header-state";
import { setFinderActionMode } from "../state/layout/layout-action";
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor, ButtongroupInline, Button, ButtonPrimary} from "@frontend-components/admin";
import { getActorStatus, noStatus } from "../domain-objects/actor";
import JoditEditor from "jodit-react";
import "./Schools.scss";
import "./wysiwyg.scss";


const buttons = [
    'source',
    '|',
    'bold',
    //'strikethrough',
    'underline',
    'italic',
    'eraser',
    // '|',
    // 'superscript',
    // 'subscript',
    '|',
    'ul',
    'ol',
    '|',
    'outdent',
    'indent',
    '|',
    //'font',
    'fontsize',
    // 'brush',
    'paragraph',
    '|',
    'image',
    // 'file',
    // 'video',
    'table',
    'link',
    '|',
    'align',
    'undo',
    'redo',
    '\n',
    'selectall',
    'cut',
    'copy',
    'paste',
    'copyformat',
    '|',
    'hr',
    'symbol',
   //'fullsize',
    'print',
    'about'
];

const config = {
    width: 722, // 754-32
    buttons: buttons,
    buttonsMD: buttons,
};

export const Admin = () => {

    const dispatch = useDispatch();


    const { t, } = useTranslation();

    const studentLoginInfoTextEditor = useRef(null)
 
    const studentInfoTextEditor = useRef(null)

   
    const [ /*actorStatus*/, setActorStatus] = useState(noStatus);
    const [studentLoginInfoText, setStudentLoginInfoText] = useState('');
    const [dirtyStudentLoginInfoText, setStudentLoginInfoTextDirty] = useState(true /* false */);

    const [studentInfoText, setStudentInfoText] = useState('');
    const [dirtyStudentInfoText, setStudentInfoTextDirty] = useState(true);

    function loadStudentLoginInfoText(ac) {
        kitFetchExternal(`/minne-api/schools/student_login_info`, (ac) ? { signal: ac.signal } : {})
            .then(result => {  
                /* setStudentLoginInfoTextDirty(false); */
                setStudentLoginInfoText(result.html);
            })
            .catch(err => console.log(err));
    }

    function saveStudentLoginInfoText() {
        setStudentLoginInfoTextDirty(false);
        kitFetchExternal(`/minne-api/schools/student_login_info`, {
            method: 'PUT',
            body: JSON.stringify({html: studentLoginInfoText})
        }).then(result => {
            loadStudentLoginInfoText();
            setStudentLoginInfoTextDirty(true);
        })
    }

    function loadStudentInfoText(ac) {
        kitFetchExternal(`/minne-api/schools/student_info_text`, (ac) ? { signal: ac.signal } : {})
            .then(result => {  
                /* setStudentLoginInfoTextDirty(false); */
                setStudentInfoText(result.html);
            })
            .catch(err => console.log(err));
    }

    function saveStudentInfoText() {
        setStudentInfoTextDirty(false);
        kitFetchExternal(`/minne-api/schools/student_info_text`, {
            method: 'PUT',
            body: JSON.stringify({html: studentInfoText})
        }).then(result => {
            loadStudentInfoText();
            setStudentInfoTextDirty(true);
        })
    }

    const handle_blur = (target, set_func) => {
        alert(target);
        var bp = true;
    };

    useEffect(() => {       
        dispatch(setFinderActionMode());
        const ac = new AbortController();
        getActorStatus(ac).then(actorStatus => {
            setActorStatus(actorStatus);
            loadStudentLoginInfoText();
            loadStudentInfoText(ac);
        });
        return () => ac.abort();
    }, [dispatch]);

    return (
        <LayoutEditor

            parents={[
                {
                    title: t('app.schools.breadcrumbs.home'),
                    url: '/'
                },
                {
                    title: t('app.schools.breadcrumbs.admin')
                }
            ]}>

            <EditorContent>

                <LayoutDocument>
                    <LayoutSection title={t('app.schools.texts.studentLoginInfoText')}>
                            <p>{t('app.schools.texts.doNotForgetToSave')} </p> 
                            <br/>
                            <ButtongroupInline>
                                <Button 
                                    className="admin-button  admin-button--primary  bg-ok" 
                                    title={t('app.schools.texts.save')} 
                                    icon="done" 
                                    disabled={!dirtyStudentLoginInfoText}
                                    onClick={saveStudentLoginInfoText}>
                                </Button>
                                <ButtonPrimary 
                                    title={t('app.schools.texts.revert')} 
                                    icon="close" 
                                    disabled={!dirtyStudentLoginInfoText}
                                    onClick={loadStudentLoginInfoText}>
                                </ButtonPrimary>
                            </ButtongroupInline>
                            <JoditEditor
                                ref={studentLoginInfoTextEditor}
                                value={studentLoginInfoText}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => {setStudentLoginInfoText(newContent)}} // preferred to use only this option to update the content for performance reasons
                                onChange={_ => { /* setStudentLoginInfoTextDirty(true)*/ }}>
                            </JoditEditor>
                        
                    </LayoutSection>
      
                    <LayoutSection title={t('app.schools.texts.studentInfoText')}>
                            <p>{t('app.schools.texts.doNotForgetToSave')} </p> 
                            <br/>
                            <ButtongroupInline>
                                <Button 
                                    className="admin-button  admin-button--primary  bg-ok" 
                                    title={t('app.schools.texts.save')} 
                                    icon="done" 
                                    disabled={!dirtyStudentInfoText}
                                    onClick={saveStudentInfoText}>
                                </Button>
                                <ButtonPrimary 
                                    title={t('app.schools.texts.revert')} 
                                    icon="close" 
                                    disabled={!dirtyStudentInfoText}
                                    onClick={loadStudentInfoText}>
                                </ButtonPrimary>
                            </ButtongroupInline>
                        
                            <JoditEditor
                                ref={studentInfoTextEditor}
                                value={studentInfoText}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => {setStudentInfoText(newContent)}} // preferred to use only this option to update the content for performance reasons
                                onChange={_ => {}} /*setStudentInfoTextDirty(true)}*/ >
                            </JoditEditor>
                        
                    </LayoutSection>
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}