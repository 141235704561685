import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        flexDirection: 'column',
        width: "320px"
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginTop: theme.spacing(2),
        alignSelf: "center"
    },
    text: {
        alignSelf: "center"
    },
}));

export const AppHeaderAppInfo = ({app}) => {
    const classes = useStyles();

    const AppIcon = ({icon, imageUrl, title}) => {
        if(!icon && imageUrl) {
            return <img className={classes.img} src={imageUrl} alt={title}/>;
        } else if(!icon) {
            return null
        } else {
            return icon
        }
    };

    return (
        <div>
            <div className={classes.root}>
                <AppIcon icon={app.icon} imageUrl={app.imageUrl} title={app.title} />
                <Typography variant="subtitle1" className={classes.text}>
                    {app.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.text}>
                    {app.version} {app.updatedAt}
                </Typography>
            </div>
            <Divider />
        </div>
    )
}