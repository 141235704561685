import React, { useState } from "react";
//import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Button } from "@frontend-components/admin";

export const ResponseItem = ({ responseItem }) => {

    const [openImage, setOpenImage] = useState(false);

    //const { t, } = useTranslation();

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1
        },
        content: {
            border: 'none',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };

    const licenseIcons = {
        "CC0": "https://licensebuttons.net/l/zero/1.0/80x15.png",
        "CC BY": "https://licensebuttons.net/l/by/1.0/80x15.png",
        "CC BY-NC": "https://licensebuttons.net/l/by-nc/1.0/80x15.png"
    }

    const licenseUrls = {
        "CC0": 'https://creativecommons.org/publicdomain/zero/1.0/deed.sv',
        "CC BY": 'https://creativecommons.org/licenses/by/4.0/deed.sv',
        "CC BY-NC": 'https://creativecommons.org/licenses/by-nc/4.0/deed.sv'
    }
   

    function mediaMetadata(media) {
        return (
            <div className="media-metadata">
                {!!media.description &&
                    <p className="media-text">{media.description}</p>}
                {!!media.owner &&
                <span className="media-metadata-item">{media.owner}</span>}
                {!!media.license && !!media.license.url &&
                    <span className="media-metadata-item">
                        <a href={media.license.url} target="_blank">
                            <img src={licenseIcons[media.license.value]} alt={media.license.value}></img>
                        </a>
                    </span>}
            </div>
        );
    }

    switch (responseItem.typeId) {
        case 1: // Intput
        case 2: // Textarea
        case 3: // Radio
        case 4: // Checkbox
        case 5: //  Select
        case 6: //  Single date
        case 7: // Date range
            return <div className="response-item">
                {!!responseItem.label &&
                    <label className="response-item-label">{responseItem.label}</label>}
                <p className="response-item-text">{responseItem.stringRepr || "-"}</p>
            </div>;
        case 8: // Form help text 
            return null;
        case 9: // Media 
            return <div className="response-item">
                {!!responseItem.label &&
                    <label className="response-item-label">{responseItem.label}</label>
                }
                <div className="response-item-media">
                    {
                        !!responseItem.media && responseItem.media.map((media) => {
                            if (media.type === "video") {
                                return <div className="media-video" key={media.uuid}>
                                    <video controls width="250">
                                        <source src={media.dms_video_url} type={media.mime_type}></source>
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                    {mediaMetadata(media)}
                                </div>
                            } else if (media.type === "audio") {
                                return <div className="media-audio" key={media.uuid}>
                                    <audio
                                        controls
                                        src={media.dms_audio_url} >
                                        Sorry, your  browser does not support the<code>audio</code> element.
                                    </audio>
                                    {mediaMetadata(media)}
                                </div>
                            }else if (media.type === "document") {
                                return <div className="media-document" key={media.uuid}>
                                    <object data={media.dms_docuement_url} type={media.mime_type} width="500"
                                        height="400">
                                        {/* <embed src={media.dms_docuement_url} type={media.mime_type} width="250"
                                        height="400"> 
                                        </embed> */}
                                    </object>
                                    <br/>
                                    <a href={media.dms_docuement_url} target="_blank">{media.name}</a>
                                    {mediaMetadata(media)}
                                </div>
                            }
                            else if (media.type === "image") {
                                return <div className="media-image" key={media.uuid}>

                                    <img className="memory-preview-thumbnail" src={`${media.thumbnail}?dimension=250x250`}
                                        alt={media.description || media.name}
                                        onClick={() => setOpenImage(media)}
                                    />

                                    {mediaMetadata(media)}

                                    <Modal
                                        isOpen={!!openImage}
                                        style={customStyles}
                                        contentLabel="Typ en titel">
                                        <h1 className="modal-title">{!!openImage && openImage.description}</h1>
                                        <img className="memory-image-fullsize" src={!!openImage && openImage.dms_url}
                                            alt={!!openImage && (openImage.description || openImage.name)}
                                        />
                                        {!!openImage && mediaMetadata(openImage)}
                                        <div className="modal-close">
                                            <Button
                                                onClick={e => setOpenImage(null)}
                                                icon="clear" >
                                            </Button>
                                        </div>
                                    </Modal>
                                </div>
                            }
                        })
                    }
                </div>
            </div>;
        case 10: // WYSIWYG 
            return <div className="response-item wysiwyg">
                {!!responseItem.label &&
                    <label className="response-item-label">{responseItem.label}</label>}
                {!!responseItem.displayValue &&
                    <div className="response-item-html" dangerouslySetInnerHTML={{ __html: responseItem.displayValue }} ></div>}
            </div>;
        case 11: // Title
            return <div className="response-item title">
                <p>Title - should not be here !!!</p>
            </div>;
        case 13: // Coordinate
            return <div className="response-item coordinate">
                <p>Coordinate TBD!</p>
            </div>;
        case 14: // Coordinates 
            return <div className="response-item coordinates">
                <p>Coordinates TBD!</p>
            </div>;
        case 15: // YouTube
            return <div className="response-item you-tube">
                <p>YouTube TBD!</p>
            </div>;
        case 16: // SoundCloud
            return <div className="response-item sound-cloud">
                <p>SoundCloud TBD!</p>
            </div>;
        default:
            return null;
    };
}