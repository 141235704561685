import {combineReducers} from 'redux'
import headerReducer from "@kulturit/header-state";
import layoutReducer from "./layout/layout-reducer";
import topicReducer from "./topic/topic-reducer";
import schoolReducer from "./school/school-reducer";

export default () => combineReducers({
    header: headerReducer,
    layout: layoutReducer,
    topic: topicReducer,
    school: schoolReducer
})