import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router} from "react-router-dom";
import AppRoutes from "./routing/AppRoutes";
import Authenticator from "@kulturit/header-state/es/auths/Authenticator";
import {Layout} from "./layout/Layout";

class App extends Component {
    render() {
        return (
                <Router>
                    <Authenticator>
                        <main>
                            <Layout>
                                <AppRoutes />
                            </Layout>
                        </main>
                    </Authenticator>
                </Router>
        );
    }
}

export default App;
