import React, {Component} from 'react';
import Page404 from "../common/Page404";
import {Route, Switch} from "react-router-dom";
import Home from "../layout/Home";
import {Schools} from "../schools/Schools";
import {MySchools} from "../schools/MySchools";
import {Teachers} from "../schools/Teachers";
import {Admin} from "../schools/Admin";
import {MyStudents} from "../schools/MyStudents";
import {ActiveSchools} from "../schools/ActiveSchools";
import {School} from "../schools/School";
import {Statistics} from "../schools/Statistics";
import {SchoolClassPage} from "../schools/SchoolClassPage";
import PrivateRoute from "@kulturit/header-state/es/auths/PrivateRoute";
import {AuthsRoutes} from "@kulturit/header-state";


class AppRoutes extends Component {

    
    render() {
        return (
            <Switch>
                <Route exact path='/' allowAll={true} component={Home}/>
                <PrivateRoute exact path='/schools/municipality/:municipalityId' allowAll={true} component={Schools}/>
                <PrivateRoute exact path='/schools' allowAll={true} component={Schools}/>
                <PrivateRoute exact path='/schools/my_schools' allowAll={true} component={MySchools}/>
                <PrivateRoute exact path='/schools/admin' allowAll={true} component={Admin}/>
                <PrivateRoute exact path='/schools/my_students' allowAll={true} component={MyStudents}/>
                <PrivateRoute exact path='/schools/teachers' allowAll={true} component={Teachers}/>
                <PrivateRoute exact path='/schools/statistics' allowAll={true} component={Statistics}/>
                <PrivateRoute exact path='/schools/active_schools' allowAll={true} component={ActiveSchools}/>
                <PrivateRoute exact path='/schools/school/:schoolId' allowAll={true} component={School}/>
                <PrivateRoute exact path='/schools/school/:schoolId/school_class/:schoolClassId' allowAll={true} component={SchoolClassPage} />            
                <AuthsRoutes/>
                <Route component={Page404}/>
            </Switch>
        )
    }
}

export default AppRoutes;

export function homeBreadcrumb(t) {
    return {
        title: t('app.schools.breadcrumbs.home'),
        url: '/'
    };
}

export function schoolsBreadcrumb(t, active = false) {
    return (!active) ?
        {
            title: t('app.schools.breadcrumbs.schools')
        } :
        {
            title: t('app.schools.breadcrumbs.schools'),
            url: '/schools'
        };
}

export function municipalityBreadcrumb(municipality, t, active = false) {
    return (!active || !municipality) ?
     {
        title: (!!municipality) ? municipality.title : "",
    } :
    {
        title: municipality.title,
        url: `/schools/municipality/${municipality.key}`
    };
}


export function schoolBreadcrumb(school, t, active = false) {
    return (!active || !school) ?
        {
            title: (!!school) ? school.title : ""
        } :
        {
            title: school.title,
            url: `/schools/school/${school.key}`
        };
}

export function schoolClassBreadcrumb(schoolClass, t, onClick=null) {
    return (!onClick || !schoolClass) ?
    {
        title: (!!schoolClass) ? classTitle(schoolClass, t) : "" 
    }:
    {
        title: classTitle(schoolClass, t),
        onClick: onClick
    };
}

export function studentBreadcrumb(student, t, onClick=null) {
    return (!onClick || !student) ?
    {
        title: (!!student)?`${student.name} ${student.familyName}`:""
    }:
    {
        title: `${student.name} ${student.familyName}`,
        onClick: onClick
    };
}

const classTitle = (schoolClass, t) => {
    if("10" !== schoolClass.grade) {
        return `${t('app.schools.breadcrumbs.schoolClass')} ${schoolClass.title}`
    } else {
        return `GY - ${schoolClass.name}`
    }
};