import { kitFetchExternal } from "@kulturit/header-state";

export const noStatus = {
    isAdmin : false,    
    isUser : false,
    isTeacher : false,
    isTeacherAtSchool : false,
    isTeacherInSchoolClass : false
};



export function getActorStatus(ac) {
    let futureResolve; //, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        //futureReject = reject;
    });
    let actorStatusFuture = kitFetchExternal(`/minne-api/schools/actor_status`, (ac) ? { signal: ac.signal } : {});
    actorStatusFuture.then((json) => {
        // No processing needed
        futureResolve(json.actorStatus);
    }).catch(err => {
        // Log and handle error
        // futureReject(err);
        futureResolve(noStatus);
    });
    return result;
}

export function getActorStatusForSchool(schoolId, ac) {
    let futureResolve; //, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        //futureReject = reject;
    });
    let actorStatusFuture = kitFetchExternal(`/minne-api/schools/school/${schoolId}/actor_status`, (ac) ? { signal: ac.signal } : {});
    actorStatusFuture.then((json) => {
        // No processing needed
        futureResolve(json.actorStatus);
    }).catch(err => {
        // Log and handle error
        // futureReject(err);
        futureResolve(noStatus);
    });
    return result;
}

