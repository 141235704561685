import React from 'react';
import AppBar from "@material-ui/core/AppBar";
// import IconButton from "@material-ui/icons";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";

export const AppHeader = ({children, backgroundColor=null}) => {
    const classes = useStyles({backgroundColor});

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    
                    {children}
                </Toolbar>
            </AppBar>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    appBar: {
        backgroundColor: props => props.backgroundColor ? props.backgroundColor : theme.palette.primary.main,
    },
    toolbar: {
        justify: "space-between"
    },
    title: {
        flexGrow: 1,
    },
}));