import React from "react";
import { useTranslation } from 'react-i18next';
import { Widget, WidgetHeader, WidgetBody, Layout, Button } from "@frontend-components/admin";


export const SchoolClassListItem = ({ schoolClass, onSelect, onDelete, onEdit }) => {


    function handleSelect(e) {
        if (!e.target.classList.contains("admin-button__icon") 
            && (schoolClass.actorStatus.isTeacherInSchoolClass || schoolClass.actorStatus.isAdmin)) {
            !!onSelect && onSelect(schoolClass);
        }
    }

    function handleDelete(e) {
        e.preventDefault();
        !!onDelete && onDelete(schoolClass);
    }

    function handleEdit(e) {
        if (!!onEdit) {
            e.preventDefault();
            onEdit(schoolClass);
        }
    }

    const { t, } = useTranslation();

    const schoolClassTitle = () => {
        if ("10" !== schoolClass.grade) {
            return `${t('app.schools.breadcrumbs.schoolClass')} ${schoolClass.title}`
        } else {
            return `GY - ${schoolClass.name}`
        }
    }

    return (
        <Layout>

            <Widget onClick={e => handleSelect(e)}
                style={{ backgroundColor: schoolClass.actorStatus.isTeacherInSchoolClass ? "white" : "lightgrey" }}
            >
                <div title={`${t('app.schools.texts.clickToShowStudents')}`}>
                    <WidgetHeader style={{ height: "30px" }} >
                        <h1>
                            <span>
                                {schoolClassTitle()}
                            </span>
                            {!!onDelete &&
                                <Button
                                    style={{ float: "right" }}
                                    onClick={e => handleDelete(e)}
                                    icon="delete" >
                                </Button>
                            }
                            {!!onEdit &&
                                <Button
                                    style={{ float: "right" }}
                                    onClick={e => handleEdit(e)}
                                    icon="edit" >
                                </Button>
                            }
                        </h1>
                    </WidgetHeader>
                    <WidgetBody>
                        <p>{`${t('app.schools.texts.teachers')}:`}&nbsp;{!!schoolClass.teachers && schoolClass.teachers.map((t, i, a) => <span key={t.key} className="name-list">{t.name}{(i < a.length - 1) ? "" : ""}</span>)}</p>
                    </WidgetBody>
                </div>
            </Widget>

        </Layout>
    );
}