import { kitFetchExternal } from "@kulturit/header-state";

function extractMunicipality(entity) {
        let code = entity.properties["place.code"][0].value;
        return {
            key: entity.uuid,
            title: entity.properties["entity.name"][0].value.sv,
            code: code,
            countyUUID: entity.properties["place.isPartOf"][0].value
        };
}

function extractMunicipalities(json) {
    return json
        .filter(entity => entity.entityType === "Place")
        .map(entity => extractMunicipality(entity))
}

export function getAllMunicipalites(ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let municipalitiesFuture = kitFetchExternal(`/kulturnav-api/list/b96a2c92-971e-464d-9f6a-1a0b82a44800`, (ac) ? { signal: ac.signal } : {});
    municipalitiesFuture.then(json => {
        let municipalities = extractMunicipalities(json);
        futureResolve(municipalities);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}

export function getMunicipality(uuid, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let municipalityFuture = kitFetchExternal(`/kulturnav-api/${uuid}`, (ac) ? { signal: ac.signal } : {});
    municipalityFuture.then(json => {
        let municipality = extractMunicipality(json);
        futureResolve(municipality);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}

