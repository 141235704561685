import { getTopic } from "../state/topic/topic-action";

export class Topic {
    constructor(json) {
        this.id = json.id;
        this.name = getLocalizedOrAsIs(json.name);
        this.items = json.topic_items;
        this.imageUrl = json.image_url;
        //TODO: 
        //this.items = json.topic_items.map( t=>  new TopicItem(t));
    }
}

export function getTopics(listOfTopicIds, dispatch, cache = null) {
    let topics = new Map();
    let topicFutures = [];
    listOfTopicIds.forEach(topicId => {
        if (cache && cache[topicId]) {
            topics.set(topicId, cache[topicId]);
        } else {
            let futureTopic, noFutureTopic;
            topicFutures.push(
                new Promise(function (resolve, reject) {
                    futureTopic = resolve;
                    noFutureTopic = reject;
                })
            );
            dispatch(getTopic(topicId, futureTopic, noFutureTopic));
        }
    });
    return new Promise((resolve, reject) => {
        Promise.all(topicFutures)
            .then(listOfTopics => {
                listOfTopics.forEach((t) => {
                    topics.set(t.id, t);
                });
                resolve(topics);
            }).catch(err => {
                reject(err);
            });

    });
}

function getLocalizedOrAsIs(o, defaultValue = '') {
    if (o !== null && typeof o === 'object') {
        var locale = "sv_se";
        /* var defaultLocale = "sv_se"; */
        return o[locale] /* || o[defaultLocale] */ || Object.values(o)[0] || defaultValue;
    } else {
        return o || defaultValue;
    }
}

