import React from 'react';
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: "flex",
        flexDirection: 'column',
        width: "320px"
    },
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginTop: theme.spacing(2),
        alignSelf: "center"
    },
    text: {
        alignSelf: "center"
    },
}));

export const AppHeaderUserInfo = ({user}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.root}>
                    <Avatar
                        alt={user.name}
                        src={user.imageUrl}
                        className={classes.avatar}
                    />
                <Typography variant="subtitle1" className={classes.text}>
                    {user.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.text}>
                    {user.email}
                </Typography>
            </div>
            <Divider />
        </div>
    )
};