import {applyMiddleware, compose, createStore} from 'redux'
import rootReducer from './index'
import thunk from 'redux-thunk'

const middleware = [thunk];

export default function configureStore(preloadedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(
        rootReducer(),
        preloadedState,
        composeEnhancers(
            applyMiddleware(...middleware)
        ),
    )
};
