import React from 'react';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Button as MaterialButton } from "@material-ui/core";

import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';

export const PrimaryButton = ({ label, startIcon, onClick, disabled }) => {

    const { t, } = useTranslation();

    return ( <MaterialButton
              disabled={disabled} 
              variant="contained"
              color={"primary"}
              startIcon={startIcon}
              onClick={onClick}>{(!!label)?t(label):""}</MaterialButton>
  );

}

export const AddButton = ({ label, startIcon, onClick, disabled }) => {

  return (
        <PrimaryButton
            disabled={disabled} 
            startIcon={!!startIcon?startIcon:<AddIcon></AddIcon>}
            onClick={onClick}
            label={(!!label)?label:'app.schools.texts.add'} />
  );

}

export const DefaultButton = ({ label, startIcon, onClick, disabled }) => {

  const { t, } = useTranslation();

  return ( <MaterialButton
            disabled={disabled} 
            variant="contained"
            startIcon={startIcon}
            onClick={onClick}>{(!!label)?t(label):""}</MaterialButton>
  );

}

export const AffirmButton = ({ label, startIcon, onClick, disabled }) => {

  const theme = createMuiTheme({
      palette: {
        primary: {    
          main:"#00994D"
        }
      },
    });

    return (
      <ThemeProvider theme={theme}>
          <PrimaryButton
              disabled={disabled} 
              startIcon={!!startIcon?startIcon:<ThumbUpIcon></ThumbUpIcon>}
              onClick={onClick}
              label={label} />
      </ThemeProvider>

  );

}

export const SaveButton = ({ label, startIcon, onClick, disabled }) => {

    return (
          <AffirmButton
              disabled={disabled} 
              startIcon={!!startIcon?startIcon:<DoneIcon></DoneIcon>}
              onClick={onClick}
              label={(!!label)?label:'app.schools.texts.save'} />
  );

}

export const RejectButton = ({ label, startIcon, onClick, disabled }) => {

  const theme = createMuiTheme({
      palette: {
        primary: {    
          main:"#de6773"
        }
      },
    });

    return (
      <ThemeProvider theme={theme}>
          <PrimaryButton
              disabled={disabled} 
              startIcon={!!startIcon?startIcon:<ThumbDownIcon></ThumbDownIcon>}
              onClick={onClick}
              label={label} />
      </ThemeProvider>

    );
  }

  export const AbortButton = ({ label, startIcon, onClick, disabled }) => {

    const theme = createMuiTheme({
        palette: {
          primary: {    
            main:"#FFF"
          }
        },
      });
  
      return (
        <ThemeProvider theme={theme}>
            <PrimaryButton
                disabled={disabled} 
                startIcon={!!startIcon?startIcon:<CloseIcon></CloseIcon>}
                onClick={onClick}
                label={!!label?label:'app.schools.texts.abort'} />
        </ThemeProvider>
  
    );

}