import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const AppHeaderDrawer = ({clickHandler, visible=true}) => {
    const classes = useStyles();

    if(!visible) {
        return null
    }

    return (
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => clickHandler()}
            className={classes.hamburger}
        >
            <MenuIcon />
        </IconButton>
    )
};

const useStyles = makeStyles(theme => ({
    hamburger: {
        // marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    },
}));