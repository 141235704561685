import React from 'react';
import {useSelector} from "react-redux";
import {useHistory, useLocation} from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "./Theme";
import {AppHeader} from "../appbar/AppHeader";
import {AppHeaderTitle} from "../appbar/AppHeaderTitle";
import {AppHeaderUserNav} from "../appbar/AppHeaderUserNav";
import {AppHeaderUserInfo} from "../appbar/AppHeaderUserInfo";
import {AppHeaderAppInfo} from "../appbar/AppHeaderAppInfo";
import {AppHeaderLogout} from "../appbar/AppHeaderLogout";
import {AppHeaderDrawer} from "../appbar/AppHeaderDrawer";
import {AppHeaderLogin} from "../appbar/AppHeaderLogin";
import {Menu} from "./Menu";
import {useTranslation} from "react-i18next";
import AdminBody from "@frontend-components/admin/es/components/Admin/AdminBody";


export const Layout = ({children}) => {
    const {
        user,
        auths
    } = useSelector(state => state.header);

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const {t} = useTranslation();

    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    const app = {
        title: "Minnen skola",
        version: "1.0",
        updatedAt: "26.02.2020",
        subtitle: user.name
    };

    return (
        <Theme>
            <AppHeader>
                
                <AppHeaderDrawer
                    clickHandler={() => setMobileOpen(!mobileOpen)}
                    visible={auths.loggedIn}
                />
                <img className="memoria-icon" width="201" src="/minnen-beta.svg"></img>
                <AppHeaderTitle title={app.title} subtitle={app.subtitle} />
                <AppHeaderUserNav user={user} visible={auths.loggedIn}>
                    <AppHeaderUserInfo user={user} />
                    <AppHeaderAppInfo app={app}/>
                    <AppHeaderLogout logoutHandler={() => history.push('/logout')} />
                </AppHeaderUserNav>
                <AppHeaderLogin
                    visible={!auths.loggedIn}
                    loginHandler={() => history.push('/login')}
                />
            </AppHeader>
            <AdminBody>
                <Menu
                    t={t}
                    path={location.path}
                    mobileOpen={mobileOpen}
                    closeHandler={() => setMobileOpen(false)}
                    //visible={'/' === location.pathname}
                    visible={auths.loggedIn && '/' === location.pathname}
                />
                <main className={classes.content}>
                    {children}
                </main>
            </AdminBody>
        </Theme>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        position: "absolute",
        zIndex: 1,
        width: "100%",
        // overflow: "scroll",
        // marginTop: theme.spacing(),
    },
    content: {
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginLeft: 240,
        }
    }
}));