import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { setFinderActionMode } from "../state/layout/layout-action";
import { getTopicItemTypes } from "../state/topic/topic-action";
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor } from "@frontend-components/admin";
import { Municipality } from "./Municipality.js"
import { getAllMunicipalites } from "../domain-objects/municipality";
import { getAllCounties } from "../domain-objects/county";
import { homeBreadcrumb, schoolsBreadcrumb, municipalityBreadcrumb } from "../routing/AppRoutes";
import "./Schools.scss";

export const Schools = () => {

    const dispatch = useDispatch();

    const { t,  } = useTranslation();

    const { municipalityId } = useParams();

    const [documentMenu, setDocumentMenu] = useState([]);
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);


    function buildMenu(counties, municipalities) {
        let municipalitiesPerCounty = new Map(counties.map(c => [c.uuid, { county: c, municipalities: [] }]));
        for (const m of municipalities) {
            municipalitiesPerCounty.get(m.countyUUID).municipalities.push(m);
        }
        let result = [];
        for (const [, c_ms] of municipalitiesPerCounty) {
            let children = [];
            for (const m of c_ms.municipalities) {
                let selected = false;
                if (!!selectedMunicipality) {
                    selected = m.code === selectedMunicipality.code;
                } else if (!!municipalityId && municipalityId === m.key) {
                    selected = true;
                    setSelectedMunicipality(m);
                }
                children.push(
                    {
                        title: m.title,
                        onClick: () => setSelectedMunicipality(m),
                        selected: selected
                    }
                );
            };
            
            result.push(
                {
                    key: c_ms.county.code,
                    title: c_ms.county.title,
                    children: children
                    // expanded: false,
                    // onClick : () => expandCounty(c_ms.county.code)
                }
            );
        };
        return result;
    }

    // function expandCounty(code) {
    //     let newMenu = documentMenu.map(c => (c.key===code)?{...c, expanded:true}:c);
    //     setDocumentMenu(newMenu);
    // }

    function loadMunicipalitiesAndBuildMenu(ac) {
        //let actorStatusFuture = kitFetchExternal('/minne-api/schools/actor_status', (ac) ? { signal: ac.signal } : {});
        Promise.all([getAllMunicipalites(ac), getAllCounties(ac)]).then(([municipalities, counties]) => {
            let menu = buildMenu(counties, municipalities);
            console.log(menu);
            setDocumentMenu(menu);
        }).catch(err => console.log(err));
    }


    useEffect(() => {
        dispatch(setFinderActionMode());
        dispatch(getTopicItemTypes());
        setSelectedMunicipality(null);
        const ac = new AbortController();
        loadMunicipalitiesAndBuildMenu(ac);
        return () => ac.abort();
    }, []);


    function parents() {
        let result = [homeBreadcrumb(t), schoolsBreadcrumb(t)];
        if (!!selectedMunicipality) {
            result.push(municipalityBreadcrumb(selectedMunicipality, t, false));
        } 
        return result;
    }
    return (
        <LayoutEditor parents={parents()}>
            <EditorContent>

                <LayoutDocument menu={documentMenu}>
                    {!!selectedMunicipality &&
                        <Municipality municipality={selectedMunicipality}></Municipality>
                    }
                     {!selectedMunicipality &&
                        <LayoutSection title={t('app.schools.texts.selectCountyAndMunicipality')}>
                        </LayoutSection>
                    }
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}