import { getSchool as getSchoolAction } from "../state/school/school-action";

import { kitFetchExternal } from "@kulturit/header-state";

export function extractSchools(json, municipality) {
    let result = json.map(entityJson => extractSchool(entityJson, municipality));
    return result;
}

export function extractSchool(json, municipality) {
    const entityName = json.properties["entity.name"];
    const addressProperties = json.properties["organization.address"] ? json.properties["organization.address"][0].value.properties : {};
    const placeReferenceProperties = json.properties["organization.placeReference"] ? json.properties["organization.placeReference"][0].value.properties : {};

    let result = {
        key: json.uuid,
        title: entityName[0].value.sv,
        address: {
            street: addressProperties["address.line1"] ? addressProperties["address.line1"][0].value : '',
            postalCode: addressProperties["address.postalCode"] ? addressProperties["address.postalCode"][0].value : '',
            postalPlace: addressProperties["address.postalPlace"] ? addressProperties["address.postalPlace"][0].value : '',
        },
        municipalityId: placeReferenceProperties["placeReference.place"] ? placeReferenceProperties["placeReference.place"][0].value : ''
    };
    if(!!municipality && municipality.key === result.municipalityId) {
        result.municipality = municipality;
    }
    return result;
}

export function getSchool(schoolId, dispatch, cache = null) {
    let school = null;
    let schoolFuture;
    if (cache && cache[schoolId]) {
        school = cache[schoolId];
    } else {
        let futureSchool, noFutureSchool;
        schoolFuture =
            new Promise(function (resolve, reject) {
                futureSchool = resolve;
                noFutureSchool = reject;
            });
        dispatch(getSchoolAction(schoolId, futureSchool, noFutureSchool));
    }

    return new Promise((resolve, reject) => {
        if (school) {
            resolve(school);
        } else {
            schoolFuture
                .then(school => {
                    resolve(school);
                }).catch(err => {
                    console.log(err);
                    resolve(
                        {
                            error: "No such school found",
                            reason: err,
                            schoolId: schoolId
                        }
                    );

                    //reject(err);
                });
        }
    });
}

export function getMySchools(dispatch, cachedSchools, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    kitFetchExternal('/minne-api/schools/my_schools', {
        method: 'GET'
    }).then((json) => {
        let schoolsFutures = [];
        for (let schoolId of json.schools) {
            schoolsFutures.push(getSchool(schoolId, dispatch, cachedSchools));
        };
        Promise.all(schoolsFutures)
            .then(schools => {
                let schoolsFound = schools.filter((s) => !s.error);
                // TODO: Log schools with error!!!
                futureResolve(schoolsFound);
            })
            .catch(err => {
                //console.log(err);
                futureReject(err);
            });

    }).catch(err => {
        console.log(`Problem retriving my schools`);
        futureReject(err);
    });
    return result;
}

export function getSchools(schoolIds, dispatch, cachedSchools) {
    let futureResolve; // , futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        //futureReject = reject;
    });
    let schoolsFutures = [];
        for (let schoolId of schoolIds) {
            schoolsFutures.push(getSchool(schoolId, dispatch, cachedSchools));
        };
        Promise.all(schoolsFutures)
            .then(schools => {
                let schoolsFound = schools.filter((s) => !s.error);
                // TODO: Log schools with error!!!
                futureResolve(schoolsFound);
            });
    return result;
}

export function getActiveSchools(dispatch, cachedSchools, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    kitFetchExternal('/minne-api/schools/active_schools', {
        method: 'GET'
    }).then((json) => {
        let schoolsFutures = [];
        for (let schoolId of json.schools) {
            schoolsFutures.push(getSchool(schoolId, dispatch, cachedSchools));
        };
        Promise.all(schoolsFutures)
            .then(schools => {
                let schoolsFound = schools.filter((s) => !s.error);
                // TODO: Log schools with error!!!
                futureResolve(schoolsFound);
            })
            .catch(err => {
                //console.log(err);
                futureReject(err);
            });

    }).catch(err => {
        console.log(`Problem retriving my schools`);
        futureReject(err);
    });
    return result;
}



