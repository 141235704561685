import React, { useEffect, useState } from "react";
import "./School.scss";
import { useDispatch, useSelector } from "react-redux";
import { LayoutFlex, Button } from "@frontend-components/admin";
import { Response } from "./Response.js";
import { PUBLISHED, SUBMITTED, SAVED, STUDENT_WORKING, READY_FOR_APPROVAL } from '../domain-objects/response';
import { ResponseListItem } from "./ResponseListItem";
import { getResponses, getSavedResponses } from "../domain-objects/response.js";
import { useTranslation } from 'react-i18next';
import { getActorStatus, noStatus } from "../domain-objects/actor";


export const Student = ({ student, onSelect, onUnselect }) => {

    const dispatch = useDispatch();

    const cachedTopics = useSelector(state => state.topic.topics)

    const [actorStatus, setActorStatus] = useState(noStatus);

    const [responses, setResponses] = useState(null);

    const [selectedResponse, setSelectedResponse] = useState(null);

    function getFutureResponses(ac) {
        return getResponses(student, dispatch, cachedTopics, ac);
    }

    function getFutureSavedResponses(ac) {
        return getSavedResponses(student, dispatch, cachedTopics, ac);
    }

    function loadResponses(ac) {
        
        Promise.all([getFutureResponses(ac), getFutureSavedResponses(ac)]).then(([responses, savedResponses]) => {
            // TODO: Handel errors better!
            let allResponses = [ ...(responses.responses || []),  ...(savedResponses.responses || [])];
            setSelectedResponse(null);
            setResponses(allResponses);
        });
    }

    
    function handleUnselectResponse(statusChange=false) {
        setSelectedResponse(null);
        if(statusChange) {
            loadResponses();
        }
    }


    useEffect(() => {
        const ac = new AbortController();
        getActorStatus(ac).then(actorStatus => {
            setActorStatus(actorStatus);
            loadResponses(ac);
        });
        
        return () => ac.abort();
    }, [student]);


    const { t, } = useTranslation();

    if (!!selectedResponse) {
        return (
            <Response
                response={selectedResponse}
                student={student}
                onUnselect={handleUnselectResponse}>
            </Response>);
    } else {
        return (
            <div className="student-view">    
                <div className="student-view-controls">
                    {!!student.previous && !!onSelect && (
                         <Button
                            onClick={e => onSelect(student.previous)}
                            icon="arrow_back" >
                        </Button>
                    )}
                    <Button
                        onClick={e => {if(!!onUnselect) {onUnselect(student)}}}
                        icon="clear" >
                    </Button>
                    {!!student.next && !!onSelect && (
                         <Button
                            onClick={e => onSelect(student.next)}
                            icon="arrow_forward" >
                        </Button>
                    )}
                </div>
                <h1>{`${student.name} ${student.familyName}`}</h1>
                <div className="student-body">
                    <div className="student-section">
                        <h2>{t('app.schools.texts.readyForPublishing')}</h2>
                        <LayoutFlex>
                            {!!responses && responses.filter(response => (response.state === READY_FOR_APPROVAL)).map((response) =>
                                <ResponseListItem
                                    key={response.key}
                                    student={student}
                                    response={response}
                                    onSelect={setSelectedResponse}>
                                </ResponseListItem>
                            )}
                        </LayoutFlex>
                    </div>
                    <div className="student-section">
                        <h2>{t('app.schools.texts.published')}</h2>
                        <LayoutFlex>
                            {!!responses && responses.filter(response => (response.state === PUBLISHED)).map((response) =>
                                <ResponseListItem
                                    key={response.key}
                                    student={null}
                                    response={response}
                                    onSelect={setSelectedResponse}>
                                </ResponseListItem>
                            )}
                        </LayoutFlex>
                    </div>
                    <div className="student-section">
                        <h2>{t('app.schools.texts.submitted') }</h2>
                        <LayoutFlex>
                            {!!responses && responses.filter(response => (response.state === SUBMITTED)).map((response) =>
                                <ResponseListItem
                                    key={response.key}
                                    student={null}
                                    response={response}
                                    onSelect={setSelectedResponse}>
                                </ResponseListItem>
                            )}
                        </LayoutFlex>
                    </div>
                    {actorStatus.isAdmin && 
                        <div className="student-section">
                            <h2>{t('app.schools.texts.saved')}</h2>
                            <LayoutFlex>
                                {!!responses && responses.filter(response => (response.state === SAVED )).map((response) =>
                                    <ResponseListItem
                                        key={response.key}
                                        student={null}
                                        response={response}
                                        onSelect={setSelectedResponse}>
                                    </ResponseListItem>
                                )}
                            </LayoutFlex>
                        </div>
                    }
                    <div className="student-section">
                        <h2>{t('app.schools.texts.notReadyForPublishing')}</h2>
                        <LayoutFlex>
                            {!!responses && responses.filter(response => (response.state === STUDENT_WORKING)).map((response) =>
                                <ResponseListItem
                                    key={response.key}
                                    student={student}
                                    response={response}
                                    onSelect={setSelectedResponse}>
                                </ResponseListItem>
                            )}
                        </LayoutFlex>
                    </div>
                </div>
            </div>
        );
    }

}