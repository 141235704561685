import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import useTheme from "@material-ui/core/styles/useTheme";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHistory, useLocation } from 'react-router-dom';
import { getActorStatus} from "../domain-objects/actor";

export const Menu = ({ container, t, mobileOpen, closeHandler, visible = true }) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const theme = useTheme();

    const [actorStatus, setActorStatus] = useState(null); //noStatus);
    const loggedIn = useSelector(state => state.header.auths.loggedIn);

     //const headerState = useSelector(state => state.header);

    // const logInMenuEntry = 
    // {
    //     key: "menu.logIn",
    //     title: <span className="main-menu-entry-text">{t('menu.logIn')}</span>,
    //     icon: <span><i className="material-icons main-menu-entry-icon">lock_open</i></span>,
    //     url: '/login',
    //     admin: false
    // };

    const findSchoolsMenuEntry = 
    {
        key: "menu.schoolFinder",
        title: <span className="main-menu-entry-text">{t('menu.schoolFinder')}</span>,
        icon: <span><i className="material-icons main-menu-entry-icon">search</i></span>,
        url: '/schools',
        admin: false
    };

    const yourSchoolsMenuEntry =
    {
        key: "menu.yourSchools",
        title: <span className="main-menu-entry-text">{t('menu.yourSchools')}</span>,
        icon: <i className="material-icons main-menu-entry-icon">account_balance</i>,
        url: '/schools/my_schools',
        admin: false
    };

    const adminMenuEntry =
    {
        key: "menu.admin",
        title: <span className="main-menu-admin-entry-text">{t('menu.admin')}</span>,
        icon: <i className="material-icons main-menu-admin-entry-icon">settings</i>,
        url: '/schools/admin',
        admin: true
    };

    // const yourSchoolClassesMenuEntry =
    // {
    //     key: "menu.yourSchoolClasses",
    //     title: <span className="main-menu-entry-text">{t('menu.yourSchoolClasses')}</span>,
    //     icon: <i className="material-icons main-menu-entry-icon">group</i>,
    //     url: '/schools/my_schools',
    //     admin: false
    // };


    const yourStudentsMenuEntry = 
    {
        key: "menu.yourStudents",
        title: <span className="main-menu-entry-text">{t('menu.yourStudents')}</span>,
        icon: <i className="material-icons main-menu-entry-icon">person</i>,
        url: '/schools/my_students',
        admin: false
    };

    // const yourStudentsWithMemoriesToApproveMenuEntry = 
    // {
    //     key: "menu.yourStudentsWithMemoriesToAprove",
    //     title: <span className="main-menu-entry-text">{t('menu.yourStudentsWithMemoriesToAprove')}</span>,
    //     icon: <i className="material-icons main-menu-entry-icon">how_to_reg</i>,
    //     url: '/schools/my_schools',
    //     admin: false
    // };

    const activeSchoolsMenuEntry =
    {
        key: "menu.activeSchools",
        title: <span className="main-menu-admin-entry-text">{t('menu.activeSchools')}</span>,
        icon: <i className="material-icons main-menu-admin-entry-icon">account_balance</i>,
        url: '/schools/active_schools',
        admin: true
    };

    const allTeachersMenuEntry =
    {
        key: "menu.teachers",
        title: <span className="main-menu-admin-entry-text">{t('menu.teachers')}</span>,
        icon: <i className="material-icons main-menu-admin-entry-icon">people</i>,
        url: '/schools/teachers',
        admin: true
    };

    const statisiticsMenuEntry =
    {
        key: "menu.statistics",
        title: <span className="main-menu-entry-text">{t('menu.statistics')}</span>,
        icon: <i className="material-icons main-menu-entry-icon">insights</i>,
        url: '/schools/statistics',
        admin: true
    };


    function getMenu() {
        let result=[];
        if(!! actorStatus) {
            if(actorStatus.isUser) {
                result.push(findSchoolsMenuEntry);
                if(actorStatus.isTeacher) {
                    result.push(yourSchoolsMenuEntry);
                    // result.push(yourSchoolClassesMenuEntry);
                    result.push(yourStudentsMenuEntry);
                    // result.push(yourStudentsWithMemoriesToApproveMenuEntry);
                }
                if(actorStatus.isAdmin) {
                    result.push(activeSchoolsMenuEntry);
                    result.push(adminMenuEntry);
                    result.push(allTeachersMenuEntry);
                }
                
            } 
            result.push(statisiticsMenuEntry);
            // else {
            // //if(result.length === 0) {
            //     result.push(logInMenuEntry);
            // }

        }

        return result;
    }

    // useEffect(() => {
    //     const ac = new AbortController();
    //     let hs=headerState;
    //     if(!!hs && hs.auths.loggedIn) {
    //         getActorStatus(ac).then(s => setActorStatus(s));
    //     }
    //     return () => ac.abort();
    // }, [headerState]);

    useEffect(() => {
        const ac = new AbortController();
        //if(!!loggedIn) {
            getActorStatus(ac).then(s => setActorStatus(s));
        //}
        return () => ac.abort();
    }, [loggedIn]);


    

   
    if (!visible) {
        return null
    }

    const path = location.pathname;

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            
            <List>
                {getMenu().map(item => (
                    <ListItem             
                        className={item.admin?"admin-function":"user-function"}
                        selected={path.startsWith(item.url)}
                        button
                        key={item.key}
                        onClick={() => {
                            history.push(item.url);
                            closeHandler();
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    // <nav id="nav" className={classes.drawer} aria-label="mailbox folders">
    return (
        <nav className={classes.drawer} aria-label="mailbox folders">
           
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
                <Drawer
                    container={container}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={closeHandler}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    )
};

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
}));