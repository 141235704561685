import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/styles";

export const AppHeaderTitle = ({title, subtitle}) => {
    const classes = useStyles();

    return (
        <>
            <Typography variant="body1" component="h1" className={classes.title} align="right">
                {title}:
            </Typography>
            <Typography variant="body1" component="h2" className={classes.subtitle}>
                {subtitle}
            </Typography>
        </>

    )
};

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        fontWeight: "bold",
        fontSize: 18
    },
    subtitle: {
        flexGrow: 1,
        padding: theme.spacing(1),
        fontSize: 18
    }
}));