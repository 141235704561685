import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {SCHOOL_RECEIVED} from "../state/school/school-action";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStateWithCallback from 'use-state-with-callback';
import { LayoutSection, LayoutFlex } from "@frontend-components/admin";
import { SchoolListItem } from "./SchoolListItem";
import { kitFetchExternal } from "@kulturit/header-state";
import { extractSchools } from "../domain-objects/school";
import { makeActorTeacherAtSchool } from "../domain-objects/teacher";
import { TeacherRegistrationModal } from "./TeacherRegistratioModal";
import {ProgressIndicator} from "../common/ProgressIndicator";

export const Municipality = ({ municipality }) => {

    const { t, } = useTranslation();

    const history = useHistory();

    const [schools, setSchools] = useStateWithCallback(null, schools => {
        if (!!schools && !schools.done) {
            loadSchools();
        }
    });

    const [teacherVerificationSchool, setTeacherVerificationSchool] = useState(null);

    const dispatch = useDispatch();

    function loadSchools(ac) {
        let municipalityUUID = municipality.key;
        let offset = 0;
        let done = false;
        if (!!schools) {
            offset = schools.nextOffset;
            done = schools.done;
        }
        const organizationTypes = '9105bfd9-e176-43e5-b443-98fff116a268%20OR%204ac5c64a-1894-467a-8ff5-79e72f35508d';
        if (!done) {
            kitFetchExternal(`/kulturnav-api/search/organization.placeReference.placeReference.place:${municipalityUUID},organization.type:${organizationTypes}/${offset}/20`, (ac) ? { signal: ac.signal } : {}).then(
                (json) => {
                    if (!Array.isArray(json) || !json.length) {
                        if (!!schools) {
                            setSchools({ ...schools, done: true });
                            console.log(`Loaded ${schools.loaded.length} schools for municipality ${municipality.name}, done`);
                        } else {
                            setSchools({ loaded: [], nextOffset: 0, done: false });
                            console.log(`Loaded 0 schools for municipality ${municipality.name}, done`);
                        }

                    } else {
                        let batchOfSchools = extractSchools(json, municipality);
                        let body = ({
                            "school_uuids": batchOfSchools.map(s => s.key)
                        });
                        kitFetchExternal('/minne-api/schools/actor_status', {
                            method: 'POST',
                            body: JSON.stringify(body)
                        }).then((json) => {
                            batchOfSchools.forEach(school => school["actorStatus"] = json.actorStatuses[school.key]);
                            if (!!schools) {
                                setSchools({ loaded: [...schools.loaded, ...batchOfSchools], nextOffset: offset + 20, done: false });
                            }
                            else {
                                setSchools({ loaded: batchOfSchools, nextOffset: offset + 20, done: false });
                            }
                        }).catch((err) => {
                            console.log(`Problem retriving actor stautuse for ${offset} batch of schools of municipality ${municipality.name}: ${err}`);
                            batchOfSchools.forEach(school => batchOfSchools["actorStatus"] = {});
                            if (!!schools) {
                                setSchools({ loaded: [...schools.loaded, ...batchOfSchools], nextOffset: offset + 20, done: false });
                            }
                            else {
                                setSchools({ loaded: batchOfSchools, nextOffset: offset + 20, done: false });
                            }
                        });
                    }

                }
            ).catch(err => {
                console.log(`Problem retriving schools for ${offset} batch of schools of municipality ${municipality.name}: ${err}`);
            });
        }
    }


    function verifyTeacher(personnummer) {
        makeActorTeacherAtSchool(teacherVerificationSchool, personnummer)
        .then((json) => {
            setTeacherVerificationSchool(null);
            history.push(`/schools/school/${teacherVerificationSchool.key}`);
        }).catch(err => {
            setTeacherVerificationSchool(null);
            // TODO: Tell the user why not!!!
            console.log(`Problem making teacher actor att schools: ${err}`);
        });
    }


    function handleSchoolSelect(school) {
        if (school.actorStatus.isTeacherAtSchool) {
            dispatch({
                type: SCHOOL_RECEIVED,
                topicId: school.key,
                topic: school
            });
            history.push(`/schools/school/${school.key}`);
        }
        else {
            setTeacherVerificationSchool(school);

        }
    }

    function isRegistredTeacher(school) {
        return !!school && !!school.actorStatus && school.actorStatus.isTeacherAtSchool;
    }


    useEffect(() => {
        const ac = new AbortController();
        setSchools({ loaded: [], nextOffset: 0, done: false });
        return () => ac.abort();
    }, [municipality]);

    if(!schools || !schools.loaded.length) {
        return <ProgressIndicator />
    }

    return (
        <LayoutSection title={t('app.schools.texts.selectSchool')}>
            <LayoutFlex>
                <TeacherRegistrationModal
                    school={teacherVerificationSchool}
                    onVerify= {verifyTeacher}
                    onAbort= {() => setTeacherVerificationSchool(null)}
                ></TeacherRegistrationModal>
                {!!schools && !!schools.loaded && schools.loaded.map((school) => {
                    return <SchoolListItem
                        school={school}
                        isMySchool={isRegistredTeacher(school)}
                        onSelect={handleSchoolSelect}> </SchoolListItem>;

                })}
            </LayoutFlex>
        </LayoutSection>
    );



}