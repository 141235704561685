import { kitFetchExternal } from "@kulturit/header-state";

function extractTeacher(t) {
    return ({
        key: t.id, //ekultur_id,
        id: t.ekultur_id,
        name: t.name,
        email: t.email,
        personnummer: t.personnummer,
        schoolClasses: !!t.schoolClasses?t.schoolClasses.map(c => c.id):[],
        isCurrentActor: t.isCurrentActor
    });
}

export function extractTeachers(json) {
    return json.map(entity => extractTeacher(entity));
}

export function getAllTeachersAtSchool(school, ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let teachersFuture = kitFetchExternal(`/minne-api/schools/school/${school.key}/teachers`, (ac) ? { signal: ac.signal } : {});
    teachersFuture.then((json) => {
        let teachers = extractTeachers(json.teachers);
        futureResolve(teachers);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}

export function getAllTeachers(ac) {
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let teachersFuture = kitFetchExternal(`/minne-api/schools/teachers`, (ac) ? { signal: ac.signal } : {});
    teachersFuture.then((json) => {
        let teachers = extractTeachers(json.teachers);
        futureResolve(teachers);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}


export function makeActorTeacherAtSchool(school, actorPersonnummer) {
    let body = { "personnummer": actorPersonnummer }
    return kitFetchExternal(`/minne-api/schools/school/${school.key}/teacher`, {
        method: 'POST',
        body: JSON.stringify(body)
    });
}

export function addTeacherToClass(teacher, schoolClass) {
    let rel = { school_class_id: schoolClass.key, teacher_id: teacher.key }
    return kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class_teacher`, {
        method: 'POST',
        body: JSON.stringify(rel)
    });
}

export function removeTeacherFromClass(teacher, schoolClass) {
    return kitFetchExternal(`/minne-api/schools/school/${schoolClass.school.key}/school_class/${schoolClass.key}/teacher/${teacher.key}`, {
        method: 'DELETE'
    });
}


