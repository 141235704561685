import React from "react";
// import { useTranslation } from 'react-i18next';
import { Layout } from "@frontend-components/admin";
import "./ResponseListItem.scss";


export const ResponseListItem = ({ student, response, onSelect }) => {

    function handleSelect(e) {
        if (!e.target.classList.contains("admin-button__icon")) {
            if(!!onSelect) { onSelect(response); }
        }
    }

    function getAuthorName() {
        let fromResponse = response.getAuthorName();
        if(! fromResponse) {
            if(!!student) {
                return student.name;
            } else {
                return "-";
            }
        } else {
            return fromResponse;
        }
        
    }

    // const { t, } = useTranslation();

    // TODO: Check if student is defined and show student name and class
    // To be used in view with responses only.

    return (
        <Layout key={response.key}>
            <div className="response-list-item" onClick={e => handleSelect(e)}>

            <div className="response-list-item__top">
                <div className="response-list-item__image" >
                    <img
                        src={`${response.imageUrl}?dimension=250x250`}
                        alt="">
                    </img>
                    <div className="signature">
                        {getAuthorName()}
                    </div>
                </div>
                <div className="response-list-item__content">
                    <div className="response-list-item__topic">
                        {response.topic.name}
                    </div>
                    <div className="response-list-item__title">
                        {response.title}
                    </div>
                    <div className="response-list-item__slogan_with_title">
                        {response.slogan}
                    </div>
                </div>
            </div>
        </div>
    </Layout>);
}