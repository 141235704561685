import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
//import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { createMuiTheme } from '@material-ui/core/styles';
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {Box} from "@material-ui/core";
import {lightBlue} from "@material-ui/core/colors";

export const Theme = ({children}) => {
    const appTheme = createAppTheme();
    const classes = useStyles();

    return (
        <ThemeProvider theme={appTheme}>
            <Box className={classes.root}>
                {children}
            </Box>
        </ThemeProvider>
    )
};

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
}));

const createAppTheme = () => createMuiTheme({
    palette: {
        primary: {    
            main: '#ffdf86'
          },
          secondary: {
            main: '#de6773'
          },
          contrastThreshold: 3,
          tonalOffset: 0.2
    }
});


