import { kitFetchExternal } from "@kulturit/header-state";
import { extractTeachers} from "../domain-objects/teacher";

export function extractSchoolClass(scl, school) {
    let sc = null;
    if(!!school) {
        sc=Object.assign({}, school);
    } else if(!!scl.school_uuid) {
        sc={
            key: scl.school_uuid,
            title: "-"
        }
    }
    return ({
        key: scl.id,
            name: scl.name || "",
            grade: scl.grade,
            title: `${scl.grade}${(scl.name)?" - "+scl.name:""}`,
            school: sc,
            teachers: extractTeachers(scl.teachers),
            hasStudents: scl.hasStudents,
            actorStatus: scl.actorStatus,
            //unselect: () => loadClasses(school) // Note: LoadClasses also sets selected to null
    });
}

function extractSchoolClasses(json, school) {
    let result = json.map(sc => extractSchoolClass(sc, school));
    return result;
}



/*
 * IMPORTANT: Result is a struct:
   {
        schoolClasses: [...classes...], 
        actorStatus: {...actor status...} 
    }
 */
export function getClassesAtSchool(school, ac) {
    let key = school.key;
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let schoolClassesFuture = kitFetchExternal(`/minne-api/schools/school/${key}/school_classes`, (ac) ? { signal: ac.signal } : {});
    schoolClassesFuture.then(json => {
        let schoolClasses = extractSchoolClasses(json.schoolClasses, school);
        futureResolve({schoolClasses: schoolClasses, actorStatus: json.actorStatus});
    }).catch(err => {
        futureReject(err);
    });
    return result;
}


export function getSchoolClass(school, schoolClassId, ac) {
    let key = school.key;
    let futureResolve, futureReject;
    let result = new Promise((resolve, reject) => {
        futureResolve = resolve;
        futureReject = reject;
    });
    let schoolClassFuture = kitFetchExternal(`/minne-api/schools/school/${key}/school_class/${schoolClassId}`, (ac) ? { signal: ac.signal } : {});
    schoolClassFuture.then(json => {
        let schoolClass = extractSchoolClass(json, school);
        futureResolve(schoolClass);
    }).catch(err => {
        futureReject(err);
    });
    return result;
}

export function updateSchoolClass(schoolClass) {
    let key = schoolClass.school.key;
    return kitFetchExternal(`/minne-api/schools/school/${key}/school_class/${schoolClass.key}`, {
        method: 'PATCH',
        body: JSON.stringify(schoolClass)
    });
}

export function isValidNewClass(schoolClass, schoolClasses) {
    let valid = schoolClass.name !== null; // && schoolClass.name !== "";
    valid = valid && schoolClasses.every(sc =>
        !((sc.grade === schoolClass.grade) && (sc.name === schoolClass.name)));
    return valid
}

export function addClassToSchool(schoolClass, school) {
    let key = school.key;
    return kitFetchExternal(`/minne-api/schools/school/${key}/school_class`, {
        method: 'POST',
        body: JSON.stringify(schoolClass)
    });
}

export function mayBeDeleted(schoolClass) {
    return schoolClass.actorStatus.isTeacherInSchoolClass && !schoolClass.hasStudents;
}

export function removeClassFromSchool(schoolClass, school) {
    return kitFetchExternal(`/minne-api/schools/school/${school.key}/school_class/${schoolClass.key}`, {
        method: 'DELETE'
    });
}

export const grades = [
    { value: "1", label: "Årskurs 1" },
    { value: "2", label: "Årskurs 2" },
    { value: "3", label: "Årskurs 3" },
    { value: "4", label: "Årskurs 4" },
    { value: "5", label: "Årskurs 5" },
    { value: "6", label: "Årskurs 6" },
    { value: "7", label: "Årskurs 7" },
    { value: "8", label: "Årskurs 8" },
    { value: "9", label: "Årskurs 9" },
    { value: "10", label: "GY" }
]


