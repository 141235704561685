import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { getActiveSchools } from "../domain-objects/school";
import { setFinderActionMode } from "../state/layout/layout-action";
import { getTopicItemTypes } from "../state/topic/topic-action";
import { EditorContent, LayoutDocument, LayoutSection, LayoutEditor, LayoutFlex } from "@frontend-components/admin";
import { SchoolListItem } from "./SchoolListItem";
import { getActorStatus } from "../domain-objects/actor";

import "./Schools.scss";

export const ActiveSchools = () => {

    const dispatch = useDispatch();
    const cachedSchools = useSelector(state => state.school.schools);

    const { t, } = useTranslation();
    const history = useHistory();

    //const [actorStatus, setActorStatus] = useState(noStatus);
    const [schools, setSchools] = useState([]);

    function loadSchools(ac) {
        getActiveSchools(dispatch, cachedSchools, ac)
            .then(schools => setSchools(schools))
            .catch(err => {
                //TODO: Handle error better!
                console.log(`Problem retriving my schools`);
            });
    }


    function handleSchoolSelect(school) {
        history.push(`/schools/active_schools`);
        history.push(`/schools/school/${school.key}`);
    }

    useEffect(() => {
        dispatch(setFinderActionMode());
        dispatch(getTopicItemTypes());
        const ac = new AbortController();
        getActorStatus(ac).then(actorStatus => {
            //setActorStatus(actorStatus);
            if(actorStatus.isAdmin) {
                loadSchools(ac);
            }
        });
        
        return () => ac.abort();
    }, []);

    return (
        <LayoutEditor

            parents={[
                {
                    title: t('app.schools.breadcrumbs.home'),
                    url: '/'
                },
                {
                    title: t('app.schools.breadcrumbs.activeSchools')
                }
            ]}>

            <EditorContent>

                <LayoutDocument>
                    <LayoutSection title={t('app.schools.texts.selectSchool')}>
                        <LayoutFlex>
                            {!!schools && schools.map((school) => {
                                return (
                                    <SchoolListItem
                                        key={school.key}
                                        school={school}
                                        isMySchool={true}
                                        onSelect={handleSchoolSelect}
                                    >
                                    </SchoolListItem>
                                );

                            })}
                        </LayoutFlex>
                    </LayoutSection>
                </LayoutDocument>
            </EditorContent>

        </LayoutEditor >
    );
}