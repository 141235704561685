import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Widget, Layout} from "@frontend-components/admin";
import Button from "@material-ui/core/Button";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import {SaveButton, AbortButton} from '../gui-components/Buttons'

export const StudentEditListItem = ({ student, onSave, onAbort }) => {

    const [editedStudent, setEditedStudent] = useState(null);
 

    function updateStudentName(newName) {
        let updatedStudent = { ...editedStudent, name: newName };
        updatedStudent = { ...updatedStudent, valid: isValidStudent(updatedStudent) };
        setEditedStudent(updatedStudent);
    }

    function updateStudentFamilyName(newFamilyName) {
        let updatedStudent = { ...editedStudent, familyName: newFamilyName };
        updatedStudent = { ...updatedStudent, valid: isValidStudent(updatedStudent) };
        setEditedStudent(updatedStudent);

    }

    function isValidStudent(student) {
        let valid = student.name !== null && student.name !== "";
        valid = valid && student.familyName !== null && student.familyName !== "";
        return valid;
    }



    function saveEditedStudent() {
        if (!!onSave && editedStudent.valid) {
            onSave(editedStudent);
        }
    }

    function abortEdit() {
        setEditedStudent(null);
        onAbort(student);
    }

    const { t, } = useTranslation();

    useEffect(() => {
        if(!!student) { 
            setEditedStudent(
                // { ...student, valid: isValidStudent(student) }
                { 
                    key: student.key,
                    schoolClass: student.schoolClass,
                    name: student.name, 
                    familyName: student.familyName, 
                    valid: isValidStudent(student) 
                }
                );
        } else {
            setEditedStudent(
                { 
                    name: "", 
                    familyName: "", 
                    valid: false 
                });
        }
    }, [student]);

  
    // const handelSetChristianName = (event) => {
    //     updateStudentName(event.target.value);
    //   };

    //   const handelSetFamilyName = (event) => {
    //     updateStudentFamilyName(event.target.value);
    //   };
   
    const useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
          },
        },
      }));


    const classes = useStyles();

    if (!!editedStudent) {
        return (
            <Layout>  
                <Widget>
                    <form  className={classes.root} noValidate autoComplete="off">
                        <FormControl>
                            <InputLabel htmlFor="component-christianName">{t('app.schools.texts.christianName')}</InputLabel>
                            <Input 
                                id="component-christianName" 
                                variant="filled"
                                value={editedStudent.name} 
                                onChange={(event) => updateStudentName(event.target.value)} />
                        </FormControl>
                        <FormControl>
                            <InputLabel htmlFor="component-familyName">{t('app.schools.texts.familyName')}</InputLabel>
                            <Input 
                                id="component-familyName" 
                                variant="filled"
                                value={editedStudent.familyName} 
                                onChange={(event) => updateStudentFamilyName(event.target.value)} />
                        </FormControl>
                        
                    </form>
                    <div className="button-group">
                        <SaveButton onClick={saveEditedStudent} disabled={!onSave || !editedStudent.valid} />
                        <AbortButton onClick={abortEdit} />
                    </div>
                </Widget>
            </Layout>
        )
    } else { return null; };
}


{/* <LayoutField>
<FieldLabel label={t('app.schools.texts.christianName')}></FieldLabel>
<FieldInput
    title={t('app.schools.texts.christianName')}
    value={editedStudent.name}
    onChange={updateStudentName}
    required={true}>
</FieldInput>
<FieldLabel label={`${t('app.schools.texts.familyName')} (${t('app.schools.texts.neverShown')})`}></FieldLabel>
<FieldInput
    title={t('app.schools.texts.familyName')}
    value={editedStudent.familyName}
    onChange={updateStudentFamilyName}
    required={true}>
</FieldInput>              
<ButtongroupInline>
    <div className="button-group">
        <OkButton
            disabled={!onSave || !editedStudent.valid} 
            onClick={saveEditedStudent} />
        <Button
            variant="contained"
            startIcon={<CloseIcon />}
            onClick={abortEdit}
        >{t('app.schools.texts.abort')}
        </Button>
    </div>
    <ButtonPrimary 
        disabled={!onSave || !editedStudent.valid} 
        title={t('app.schools.texts.save')} 
        icon="done" 
        onClick={saveEditedStudent}
    >
    </ButtonPrimary>
    <ButtonSecondary 
        title={t('app.schools.texts.abort')} 
        icon="close" 
        onClick={abortEdit}
    >
    </ButtonSecondary>
</ButtongroupInline>
</LayoutField> */}